import React, { useState, useRef, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
  LinearProgress,
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { CloudDownload } from '@mui/icons-material';
import '@fontsource/oswald';
import { Refresh as RefreshIcon } from '@mui/icons-material';

const notionBlack = '#191919';
const notionGrey = '#202020';
const offWhite = '#CFCFCF';
const magenta = '#FF00FF';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: offWhite,
    },
    secondary: {
      main: notionBlack,
    },
    background: {
      default: notionBlack,
      paper: notionGrey,
    },
    text: {
      primary: offWhite,
      secondary: offWhite,
    },
  },
  typography: {
    fontFamily: 'Oswald, Arial, sans-serif',
    h4: {
      fontWeight: 500,
      color: offWhite,
    },
    h6: {
      fontWeight: 400,
      color: offWhite,
    },
    button: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: offWhite,
          backgroundColor: notionGrey,
          '&:hover': {
            backgroundColor: '#2c2c2c',
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: notionBlack,
            color: offWhite,
            '&:hover': {
              backgroundColor: magenta,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
  },
});

const SUPPORTED_FILE_TYPES = ['audio/mpeg', 'audio/wav'];
const MAX_FILE_SIZE = 80 * 1024 * 1024; // 80MB in bytes

export default function TheSplit() {
  const [audioFile, setAudioFile] = useState(null);
  const [option, setOption] = useState('2track');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [isWaitingForUrl, setIsWaitingForUrl] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [isWaitingForServer, setIsWaitingForServer] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // Add this line
  const MAX_CHECKS = 60; // Maximum number of checks (5 minutes at 5-second intervals)
  const fileInputRef = useRef(null);

  const validateFile = (file) => {
    if (!file) {
      setErrorMessage('Please select a file.');
      return false;
    }
  
    const fileName = file.name.toLowerCase();
    const fileExtension = fileName.split('.').pop();
    const isWav = fileExtension === 'wav';
    const isMp3 = fileExtension === 'mp3';
  
    if (!isWav && !isMp3) {
      setErrorMessage('Unsupported file type. Please upload a WAV or MP3 file.');
      return false;  // Fixed: return false instead of undefined
    }
  
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage('File size exceeds 80MB limit. Please choose a smaller file.');
      return false;
    }
  
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function(e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 12); // Read 12 bytes instead of 4
        let header = "";
        for(let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        
        // Check file signatures
        if (isWav && header.startsWith('52494646')) {  // "RIFF"
          resolve(true); // Valid WAV
        } else if (isMp3 && (header.startsWith('4944') || header.startsWith('fffb'))) {  // ID3 or MPEG frame sync
          resolve(true); // Valid MP3
        } else {
          setErrorMessage('The file appears to be corrupted or unreadable.');
          resolve(false);
        }
      };
  
      reader.onerror = function() {
        setErrorMessage('Error reading the file. It may be corrupted.');
        resolve(false);
      };
  
      reader.readAsArrayBuffer(file);
    });
  };
  
  const triggerFileInput = () => {
    // console.log('Triggering file input');
    fileInputRef.current.click();
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setErrorMessage(null); // Clear any previous error message
    
    if (file) {
      const isValid = await validateFile(file);
      if (isValid) {
        setAudioFile(file);
        setToast({ severity: 'success', message: 'File validated successfully.' });
      }
    }
  };

  const handleStemSplitting = async () => {
    if (!audioFile) {
      setToast({ severity: 'error', message: 'Please select an audio file first.' });
      return;
    }

    const isValid = await validateFile(audioFile);
    if (!isValid) {
      return;
    }

  
    setIsUploading(true);
    setUploadProgress(0);
    setDownloadUrl(null);
    setCheckCount(0);
  
    const formData = new FormData();
    formData.append('file', audioFile);  // Append the audio file
  
    console.log('Selected option:', option);
  
    const userString = localStorage.getItem('user');
    let userEmail = '';
    let userName = '';
  
    if (userString) {
      const userData = JSON.parse(userString);
      userEmail = userData.email || '';       // Extract user email from localStorage
      userName = userData.displayName || '';  // Extract user's display name from localStorage
    } else {
      console.error('No user email found in localStorage');
      setToast({ severity: 'error', message: 'User not found. Please log in.' });
      setIsUploading(false);
      return;
    }
  
    // Append user information to formData
    formData.append('name', userName);
  
    try {
      const xhr = new XMLHttpRequest();
  
      // Add the 'option' and 'email' as query parameters
      xhr.open('POST', `https://think.philspeiser.com/split/process?email=${encodeURIComponent(userEmail)}&option=${encodeURIComponent(option)}`);
      
      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setUploadProgress(percentCompleted);
          if (percentCompleted === 100) {
            setIsWaitingForServer(true);  // Once upload reaches 100%, indicate waiting for server
          }
        }
      };
  
      // Handle the response once the upload is complete
      xhr.onload = async () => {
        setIsUploading(false);
        setIsWaitingForServer(false);
  
        if (xhr.status === 200 || xhr.status === 201) {
          const result = JSON.parse(xhr.responseText);
          if (result.task_id) {
            setTaskId(result.task_id);   // Save the task ID for tracking progress
            setIsProcessing(true);       // Set processing state to true
          } else {
            throw new Error('Invalid response format from the server.');
          }
        } else if (xhr.status === 403) {
          const result = JSON.parse(xhr.responseText);
          setToast({ 
            severity: 'warning', 
            message: result.detail?.message || 'Monthly usage limit exceeded. Please try again next month or upgrade your plan.' 
          });
        } else {
          throw new Error('An error occurred during stem splitting.');
        }
      };
  
      // Handle network error during the request
      xhr.onerror = () => {
        console.error('Network error occurred during stem splitting');
        setIsUploading(false);
        setIsWaitingForServer(false);
        setToast({ severity: 'error', message: 'Network error occurred during stem splitting.' });
      };
  
      // Send the request with formData (which includes the file and name)
      xhr.send(formData);
    } catch (error) {
      console.error('Error:', error);
      setToast({ severity: 'error', message: error.message || 'An error occurred during stem splitting.' });
      setIsUploading(false);
      setIsProcessing(false);
      setIsWaitingForServer(false);
    }
  };
  



  useEffect(() => {
    let intervalId;
    
    if ((isProcessing || isWaitingForUrl) && taskId) {
      intervalId = setInterval(async () => {
        try {
          const response = await fetch(`https://serve.philspeiser.com/api/dbgetstemsplittask?task_id=${taskId}`);
          const data = await response.json();
          
          setCheckCount(prevCount => prevCount + 1);
          
          console.log('Task status:', data.status);
          console.log('Response data:', data);

          // Handle progress and status updates based on the response
          if (data.progress) {
            setUploadProgress(data.progress); // Set the progress percentage
          }
  
          // Handle status updates and final result link
          switch (data.status) {
            case 'pending':
              setToast({ severity: 'info', message: 'Waitng for the next processing slot' });
              break;

            case 'file uploaded':
              setToast({ severity: 'info', message: 'File uploaded successfully. Splitting will start shortly.' });
              break;
            
            case 'starting splitting':
              setToast({ severity: 'info', message: 'Splitting in progress.' });
              setIsProcessing(true);  // Indicate splitting started
              break;
  
            case 'zipping':
              setToast({ severity: 'info', message: 'Zipping the stems. Almost done!' });
              setIsProcessing(true);  // Still processing
              break;
  
            case 'waiting for download links':
              setToast({ severity: 'info', message: 'Uploading the final file. Please wait for the download link.' });
              setIsProcessing(true);
              setIsWaitingForUrl(true);
              break;
  
            case 'completed':
              setIsProcessing(false);
              if (data.result_link) {
                setDownloadUrl(data.result_link);
                setIsWaitingForUrl(false);
                setToast({ severity: 'success', message: 'Stem splitting complete! File is ready for download.' });
                clearInterval(intervalId);  // Stop polling since the task is complete
              } else {
                setIsWaitingForUrl(true);  // Waiting for download URL
                setToast({ severity: 'warning', message: 'Splitting complete, waiting for download link.' });
              }
              break;
  
            default:
              setToast({ severity: 'error', message: 'Unknown status. Please try again.' });
              setIsProcessing(false);
              setIsWaitingForUrl(false);
              clearInterval(intervalId);
          }
  
          // Stop progress checks after reaching the maximum check count
          if (checkCount >= MAX_CHECKS) {
            clearInterval(intervalId);
            setIsWaitingForUrl(false);
            setToast({ severity: 'error', message: 'Failed to receive download URL. Please try again.' });
          }
        } catch (error) {
          console.error('Error checking progress:', error.message, error.stack);
          setToast({ severity: 'error', message: 'Error checking progress. Please try again.' });
          setIsProcessing(false);
          setIsWaitingForUrl(false);
          clearInterval(intervalId);
        }
      }, 3000); // Check every 3 seconds
    }
    
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isProcessing, isWaitingForUrl, taskId, checkCount]);
  

  useEffect(() => {
    // console.log('downloadUrl state changed:', downloadUrl);
  }, [downloadUrl]);

  const handleDownload = () => {
    // console.log(`Initiating download from URL: ${downloadUrl}`);
    window.open(downloadUrl, '_blank');
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ maxWidth: '4xl', margin: 'auto', p: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap:2}}>
      <Typography variant="h4" gutterBottom>THE_SPLIT</Typography>
      {downloadUrl && (
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<RefreshIcon />} 
          onClick={handleReload}
        >
          Reload
        </Button>
       )}
       </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Card sx={{ backgroundColor: 'background.paper' }}>
          {!downloadUrl && (
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mr: 2,
                    flexShrink: 1,
                    minWidth: 0,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {audioFile ? `Loaded: ${audioFile.name}` : 'No audio file selected'}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUpload />}
                  onClick={triggerFileInput}
                  disabled={isUploading || isProcessing || isWaitingForUrl || isWaitingForServer}
                  sx={{
                    backgroundColor: notionBlack,
                    color: magenta,
                    '&:hover': {
                      backgroundColor: '#2c2c2c',
                    },
                    whiteSpace: 'nowrap',
                    flexShrink: 0,
                  }}
                >
                  {audioFile ? 'REPLACE AUDIO' : 'LOAD AUDIO'}
                </Button>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="audio/wav,audio/mpeg"
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                />
                 {errorMessage && (
        <Typography color="error" variant="body2" sx={{ mt: 1, ml: 2 }}>
          {errorMessage}
        </Typography>
      )}
              </Box>
              {isUploading && (
                <Box sx={{ width: '100%', mt: 2 }}>
                  <LinearProgress variant="determinate" value={uploadProgress} />
                  <Typography variant="body2" color="text.secondary" align="center">
                    Uploading: {uploadProgress}%
                  </Typography>
                </Box>
              )}
            </CardContent>
          )}

          </Card>
          {!downloadUrl && (
            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="option-select-label">Splitting Option</InputLabel>
                <Select
                  labelId="option-select-label"
                  value={option}
                  label="Splitting Option"
                  onChange={(e) => setOption(e.target.value)}
                  disabled={isUploading || isProcessing || isWaitingForUrl || isWaitingForServer}
                >
                  <MenuItem value="2track">2 Stems (VOCAL + INSTRUMENTAL)</MenuItem>
                  <MenuItem value="6stems">6 Stems</MenuItem>
                </Select>
              </FormControl>
          )}

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, gap: 2 }}>
            {!downloadUrl && (
                <Button
                  variant="contained"
                  onClick={handleStemSplitting}
                  disabled={!audioFile || isUploading || isProcessing || isWaitingForUrl || isWaitingForServer}
                  sx={{
                    backgroundColor: notionGrey,
                    color: offWhite,
                    '&:hover': {
                      backgroundColor: '#2c2c2c',
                    },
                    '&:disabled': {
                      backgroundColor: '#1a1a1a',
                      color: '#666666',
                    },
                  }}
                >
                  {isUploading ? 'Uploading...' : isWaitingForServer ? 'Waiting for server...' : isProcessing ? 'Processing...' : isWaitingForUrl ? 'Waiting for download...' : 'Split Stems'}
                </Button>
              )}
          

                

                {downloadUrl && (
                  <Button
                    variant="contained"
                    startIcon={<CloudDownload />}
                    onClick={handleDownload}
                    sx={{
                      backgroundColor: magenta,
                      color: notionBlack,
                      '&:hover': {
                        backgroundColor: '#FF40FF', // Lighter shade of magenta
                      },
                      fontWeight: 'bold',
                      transition: 'all 0.3s ease-in-out',
                      animation: 'pulse 2s infinite',
                      '@keyframes pulse': {
                        '0%': {
                          boxShadow: '0 0 0 0 rgba(255, 0, 255, 0.7)',
                        },
                        '70%': {
                          boxShadow: '0 0 0 10px rgba(255, 0, 255, 0)',
                        },
                        '100%': {
                          boxShadow: '0 0 0 0 rgba(255, 0, 255, 0)',
                        },
                      },
                    }}
                  >
                    Download Stems
                  </Button>
                )}
              </Box>
        </Box>
        <Typography variant="body1"sx={{
               fontSize: '0.6rem',
               mt:2
              }}>MODEL-VERSION: 10_24</Typography>
        <Snackbar open={!!toast} autoHideDuration={6000} onClose={() => setToast(null)}>
          <Alert onClose={() => setToast(null)} severity={toast?.severity || "success"} sx={{ width: '100%' }}>
            {toast?.message}
          </Alert>
        </Snackbar>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isProcessing || isWaitingForUrl || isWaitingForServer}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress color="inherit" />
            <Typography variant="h6" sx={{ mt: 2 }}>
              {isWaitingForServer ? 'Waiting for server response...' : isProcessing ? 'Processing audio... Please wait.' : 'Waiting for download URL...'}
            </Typography>
          </Box>
        </Backdrop>
      </Box>
    </ThemeProvider>
  );
}
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const usePreventBackNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const preventNavigation = (event) => {
      // Cancel the event
      event.preventDefault();
      // Push the current state again to prevent back navigation
      window.history.pushState(null, '', location.pathname);
    };

    // Push the current state to the history
    window.history.pushState(null, '', location.pathname);

    // Add event listener for popstate
    window.addEventListener('popstate', preventNavigation);

    return () => {
      // Clean up the event listener
      window.removeEventListener('popstate', preventNavigation);
    };
  }, [location, navigate]);
};

export default usePreventBackNavigation;
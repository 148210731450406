import { Midi } from '@tonejs/midi';
import { Midi as TonalMidi, Chord } from 'tonal';
import { getScales } from 'scalesapi';


function transcriptionToMidi(
  transcriptionData,
  onsetThreshold = 0.2,
  chordOnsets = [],
  prettychord = false,
  minLength = 0.2,
) {
  const midi = new Midi();
  const track = midi.addTrack();

  //console.log('Chord onsets:', chordOnsets);




  // Sort note events by start time and filter based on confidence
  const sortedNotes = transcriptionData.note_events
    .filter(note => note.confidence > 0.01 && (note.end_time - note.start_time) > minLength)
    .sort((a, b) => a.start_time - b.start_time);

  //console.log(minLength);

  let usedOnsets = chordOnsets.length > 0
    ? chordOnsets
    : transcriptionData.onsets
        .filter((onset) => onset.score >= onsetThreshold)
        .map((onset) => onset.time);

  if (usedOnsets.length === 0) {
    usedOnsets = [0];
  }

  // Ensure chordOnsets are sorted
  usedOnsets.sort((a, b) => a - b);

  // Get total duration of the piece
  const totalDuration = Math.max(...transcriptionData.note_events.map(note => note.end_time));

  // Define small epsilon values
  const epsilonStart = 0.1; // Adjust as needed
  const epsilonEnd = 0.1;   // Adjust as needed

  // Prepare segments
  const segments = [];
  for (let i = 0; i < usedOnsets.length; i++) {
    const intervalStart = Math.max(usedOnsets[i], 0);
    const intervalEnd = (i + 1 < usedOnsets.length) ? usedOnsets[i + 1] - epsilonEnd : totalDuration;
    segments.push({
      intervalStart,
      intervalEnd,
      notes: {}
    });
  }

  // Assign each note to a segment
  sortedNotes.forEach(note => {
    // Find the segment where the note starts
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      if (note.start_time >= (segment.intervalStart - epsilonStart) && note.start_time < segment.intervalEnd) {
        // Assign note to this segment
        if (!segment.notes[note.midi_note]) {
          segment.notes[note.midi_note] = [];
        }
        segment.notes[note.midi_note].push(note);
        break; // Note is assigned to one segment only
      }
    }
  });

  //console.log(segments);

  // Merge notes within each segment and detect chords
  segments.forEach(segment => {
    const mergedNotes = [];
    for (const [pitch, notes] of Object.entries(segment.notes)) {
      const midiNote = parseInt(pitch, 10);

      if (prettychord === true) {
        const startTime = segment.intervalStart;
        const endTime = segment.intervalEnd;
        track.addNote({
          midi: midiNote,
          time: startTime,
          duration: endTime - startTime,
          velocity: 127,
        });
      } else {
        const startTime = Math.max(segment.intervalStart, Math.min(...notes.map(note => note.start_time)));
        const endTime = Math.min(segment.intervalEnd, Math.max(...notes.map(note => note.end_time)));

        track.addNote({
          midi: midiNote,
          time: startTime,
          duration: endTime - startTime,
          velocity: 127,
        });
      }
      mergedNotes.push(TonalMidi.midiToNoteName(midiNote, { pitchClass: true, sharps: true }));
    }

    // Detect chord from merged notes in the segment
    const detectedChords = Chord.detect(mergedNotes, { assumePerfectFifth: true });

    if (detectedChords.length > 0) {
      const chordInfo = Chord.get(detectedChords[0]);
      segment.chord = {
        intervalStart: segment.intervalStart,
        name: detectedChords[0],
        root: chordInfo.notes[0],
      };
    } else {
      segment.chord = null;
    }
  });

  //console.log("Segments with chords:", segments);

  // Detect scale from the final filtered notes
  const finalNotes = sortedNotes.map(note => TonalMidi.midiToNoteName(note.midi_note, { pitchClass: true, sharps: true }));

  // Get potential scales using SCALESAPI (limit to the first 7 notes)
  const uniquePitchClasses = [...new Set(finalNotes)].slice(0, 7);
  //console.log("Unique pitch classes:", uniquePitchClasses);
  const detectedScales = getScales("fromNotes", uniquePitchClasses);
  //console.log("Detected scales:", detectedScales);

  // Determine the final scale based on the root notes of detected chords
  const rootNotes = segments.map(segment => segment.chord?.root).filter(root => root);
  const scaleCounts = {};

  Object.entries(detectedScales).forEach(([scaleType, roots]) => {
    roots.forEach(root => {
      const matchCount = rootNotes.filter(note => note === root).length;
      if (matchCount > 0) {
        scaleCounts[`${root} ${scaleType}`] = matchCount;
      }
    });
  });

  const finalScale = Object.entries(scaleCounts).sort((a, b) => b[1] - a[1])[0]?.[0] || null;
  //console.log(`Final detected scale: ${finalScale}`);

  // Expose modified segments with chords and scale to the main script
  const exposedObject = {
    chords: segments.map(segment => segment.chord).filter(chord => chord),
    scale: finalScale
  };
  
  //console.log("Exposed object:", exposedObject);

  return { midi, exposedObject };
}

export default transcriptionToMidi;
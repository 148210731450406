import React, { useState, useRef, useEffect, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.js';
import EnvelopePlugin from 'wavesurfer.js/dist/plugins/envelope.js';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import {
  Box,
  Typography,
  Button,
  Slider,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  Radio,
  Backdrop,
} from '@mui/material';
import { PlayArrow, Pause, Stop, CloudUpload, Restore } from '@mui/icons-material';

import '@fontsource/oswald';

const notionBlack = '#191919';
const notionGrey = '#202020';
const offWhite = '#CFCFCF';
const magenta = '#FF00FF';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: offWhite,
    },
    secondary: {
      main: notionBlack,
    },
    background: {
      default: notionBlack,
      paper: notionGrey,
    },
    text: {
      primary: offWhite,
      secondary: offWhite,
    },
  },
  typography: {
    fontFamily: 'Oswald, Arial, sans-serif',
    h4: {
      fontWeight: 500,
      color: offWhite,
    },
    h6: {
      fontWeight: 400,
      color: offWhite,
    },
    button: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: offWhite,
          backgroundColor: notionGrey,
          '&:hover': {
            backgroundColor: '#2c2c2c',
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: notionBlack,
            color: offWhite,
            '&:hover': {
              backgroundColor: magenta,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
  },
});

const WAVEFORM_COLORS = ['violet', 'lightblue', 'lightgreen'];
const PROGRESS_COLORS = ['purple', 'blue', 'green'];
const REGION_COLORS = ['rgba(255, 0, 0, 0.3)', 'rgba(0, 0, 255, 0.3)'];

const SUPPORTED_FILE_TYPES = ['audio/mpeg', 'audio/wav'];
const MAX_FILE_SIZE = 80 * 1024 * 1024; // 80MB in bytes

export default function VocalCleanup() {
  const [audioFile, setAudioFile] = useState(null);
  const [wavesurfersReady, setWavesurfersReady] = useState([false, false, false]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [toast, setToast] = useState(null);
  const [exportTrack, setExportTrack] = useState(0);
  const [activeTrack, setActiveTrack] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const fileInputRef = useRef(null);
  const [initialBreathIntervals, setInitialBreathIntervals] = useState([]);
  const [initialNonVocalIntervals, setInitialNonVocalIntervals] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null); // Add this line

  const waveformRefs = [useRef(null), useRef(null), useRef(null)];
  const wavesurfers = [useRef(null), useRef(null), useRef(null)];
  const envelopePlugin = useRef(null);
  const regionsPlugins = [useRef(null), useRef(null)];
  const audioContextRef = useRef(null);
  const rafRef = useRef(null);
  const ffmpeg = new FFmpeg();


  const validateFile = (file) => {
    if (!file) {
      setErrorMessage('Please select a file.');
      return false;
    }
  
    const fileName = file.name.toLowerCase();
    const fileExtension = fileName.split('.').pop();
    const isWav = fileExtension === 'wav';
    const isMp3 = fileExtension === 'mp3';
  
    if (!isWav && !isMp3) {
      setErrorMessage('Unsupported file type. Please upload a WAV or MP3 file.');
      return false;  // Fixed: return false instead of undefined
    }
  
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage('File size exceeds 80MB limit. Please choose a smaller file.');
      return false;
    }
  
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function(e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 12); // Read 12 bytes instead of 4
        let header = "";
        for(let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        
        // Check file signatures
        if (isWav && header.startsWith('52494646')) {  // "RIFF"
          resolve(true); // Valid WAV
        } else if (isMp3 && (header.startsWith('4944') || header.startsWith('fffb'))) {  // ID3 or MPEG frame sync
          resolve(true); // Valid MP3
        } else {
          setErrorMessage('The file appears to be corrupted or unreadable.');
          resolve(false);
        }
      };
  
      reader.onerror = function() {
        setErrorMessage('Error reading the file. It may be corrupted.');
        resolve(false);
      };
  
      reader.readAsArrayBuffer(file);
    });
  };

  const preventHorizontalScroll = useCallback((e) => {
    if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  const handleWaveformScroll = useCallback((scrollLeft) => {
    wavesurfers.forEach(ws => {
      if (ws.current && ws.current.drawer && ws.current.drawer.wrapper) {
        ws.current.drawer.wrapper.scrollLeft = scrollLeft;
      }
    });
  }, [wavesurfers]);

  const convertToMp3 = async (audioFile) => {
    if (!ffmpeg.loaded) {
      await ffmpeg.load();
    }
  
    const inputName = 'input' + (audioFile.name.endsWith('.wav') ? '.wav' : '.riff');
    const outputName = 'output.mp3';
  
    await ffmpeg.writeFile(inputName, await fetchFile(audioFile));
  
    await ffmpeg.exec(['-i', inputName, '-acodec', 'libmp3lame', '-b:a', '128k', outputName]);
  
    const data = await ffmpeg.readFile(outputName);
    const mp3Blob = new Blob([data.buffer], { type: 'audio/mp3' });
    
    return new File([mp3Blob], audioFile.name.replace(/\.(wav|riff)$/i, '.mp3'), { type: 'audio/mp3' });
  };

  const preventScroll = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const syncScrollPositions = useCallback((scrollLeft) => {
    waveformRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.scrollLeft = scrollLeft;
      }
    });
  }, []);

  const handleScroll = useCallback((event) => {
    const scrollLeft = event.currentTarget.scrollLeft;
    syncScrollPositions(scrollLeft);
  }, [syncScrollPositions]);

    const initializeWavesurfers = useCallback(() => {
    if (!audioFile) return;

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }

    waveformRefs.forEach((ref, index) => {
      if (!ref.current) return;

      // console.log(`Initializing WaveSurfer instance ${index}`);

      if (wavesurfers[index].current) {
        wavesurfers[index].current.destroy();
      }

      const commonOptions = {
        container: ref.current,
        waveColor: WAVEFORM_COLORS[index],
        progressColor: PROGRESS_COLORS[index],
        responsive: true,
        height: 100,
        backend: 'WebAudio',
        audioContext: audioContextRef.current,
        scrollParent: false,
        hideScrollbar: true,
        fillParent: true,
      };

      wavesurfers[index].current = WaveSurfer.create(commonOptions);

      // console.log(`WaveSurfer instance ${index} created`);

      if (index === 0) {
        initializeEnvelopePlugin(index);
      } else {
        initializeRegionsPlugin(index);
      }

      wavesurfers[index].current.on('ready', () => {
        // console.log(`WaveSurfer instance ${index} is ready`);
        setWavesurfersReady(prev => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
        if (index === 0) {
          setDuration(wavesurfers[index].current.getDuration());
          adjustCleanTrackZoom();
        }
      });



      wavesurfers[index].current.on('audioprocess', time => {
        setCurrentTime(time);
      });

      wavesurfers[index].current.on('seek', progress => {
        const time = progress * wavesurfers[index].current.getDuration();
        syncAllPlaybackPositions(time);
      });

        wavesurfers[index].current.on('finish', () => {
      // console.log(`WaveSurfer instance ${index} finished`);
      stopAllTracks();
    });

      wavesurfers[index].current.load(URL.createObjectURL(audioFile));
    });
  }, [audioFile]);



  const adjustCleanTrackZoom = useCallback(() => {
    if (wavesurfers[0].current && waveformRefs[0].current) {
      const containerWidth = waveformRefs[0].current.clientWidth;
      const duration = wavesurfers[0].current.getDuration();
      const zoomLevel = containerWidth / duration;
      wavesurfers[0].current.zoom(zoomLevel);
    }
  }, []);


  useEffect(() => {
    const waveformContainers = waveformRefs.map(ref => ref.current);
    waveformContainers.forEach(container => {
      if (container) {
        container.addEventListener('wheel', preventHorizontalScroll, { passive: false });
      }
    });

    return () => {
      waveformContainers.forEach(container => {
        if (container) {
          container.removeEventListener('wheel', preventHorizontalScroll);
        }
      });
    };
  }, [preventHorizontalScroll]);

  useEffect(() => {
    if (audioFile) {
      initializeWavesurfers();
    }
    return () => {
      wavesurfers.forEach(ws => {
        if (ws.current) {
          try {
            ws.current.pause(); // Pause playback
            ws.current.unAll(); // Remove all event listeners
            ws.current.empty(); // Unload audio data
            ws.current.destroy(); // Destroy WaveSurfer instance
          } catch (error) {
            console.error("Error during cleanup:", error);
          }
        }
      });
      waveformRefs.forEach(ref => {
        if (ref.current) {
          ref.current.removeEventListener('scroll', handleScroll);
        }
      });
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [audioFile, initializeWavesurfers, handleScroll]);


  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setErrorMessage(null); // Clear any previous error message
    
    if (file) {
      const isValid = await validateFile(file);
      if (isValid) {
        setAudioFile(file);
        setWavesurfersReady([false, false, false]);
        setCurrentTime(0);
        setDuration(0);
        setIsProcessing(true);
      }
    }
  };


  useEffect(() => {
  if (audioFile && wavesurfersReady.every(ready => ready)) {
    handleSubmit();
  }
}, [audioFile, wavesurfersReady]);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const initializeEnvelopePlugin = (index) => {
    envelopePlugin.current = wavesurfers[index].current.registerPlugin(
      EnvelopePlugin.create({
        volume: 1,
        lineColor: 'rgba(255, 0, 0, 0.5)',
        lineWidth: '1.5px',
        dragPointSize: 5,
        dragPointFill: 'rgba(255, 0, 0, 0.8)',
        dragPointStroke: 'rgb(100, 0, 0)',
        points: [],
      })
    );
  };


  const exportAllTracks = async () => {
  if (!audioFile) {
    showToast("Error", "No audio file loaded.");
    return;
  }

  setIsExporting(true);
  try {
    // Export the cleaned track
    await exportAudioWithEnvelope();
    
    // Export the breath track (track 2)
    await exportTrackWithRegions(1);
    
    // Export the noise track (track 3)
    await exportTrackWithRegions(2);

    showToast("Success", "All tracks exported successfully.");
  } catch (error) {
    console.error("Error exporting all tracks:", error);
    showToast("Error", "Failed to export all tracks.");
  } finally {
    setIsExporting(false);
  }
};


  const syncAllPlaybackPositions = useCallback((time) => {
    wavesurfers.forEach(ws => {
      if (ws.current && typeof ws.current.seekTo === 'function' && typeof ws.current.getDuration === 'function') {
        ws.current.seekTo(time / ws.current.getDuration());
      }
    });
    setCurrentTime(time);
  }, [wavesurfers]);

  const initializeRegionsPlugin = (index) => {
    regionsPlugins[index - 1].current = wavesurfers[index].current.registerPlugin(
      RegionsPlugin.create()
    );

    attachRegionEventListeners(index);

    wavesurfers[index].current.on('dblclick', (event) => {
      const clickTime = wavesurfers[index].current.getCurrentTime();
      handleDoubleClick(index, clickTime);
    });

    wavesurfers[index].current.on('click', (event) => {
      const clickTime = wavesurfers[index].current.getCurrentTime();
      // console.log(`Clicked on waveform ${index + 1} at ${clickTime.toFixed(2)} seconds`);
      pauseAllTracks();
      syncAllPlaybackPositions(clickTime);
    });
  };

  const attachRegionEventListeners = useCallback((trackIndex) => {
    const regions = regionsPlugins[trackIndex - 1].current.getRegions();
    Object.values(regions).forEach(region => {
      attachListenersToRegion(trackIndex, region);
    });

    wavesurfers[trackIndex].current.on('region-created', (region) => {
      attachListenersToRegion(trackIndex, region);
    });
  }, []);

  const handleRegionChange = useCallback((trackIndex) => {

    pauseAllTracks();
    updateEnvelopeFromRegions();
    
    if (wavesurfers[trackIndex].current && wavesurfers[trackIndex].current.isPlaying()) {
     
    

      console.log("CurrentTime");



      if (wavesurfers[trackIndex].current) {
      const currentTime = wavesurfers[trackIndex].current.getCurrentTime();
      wavesurfers[trackIndex].current.pause();
      wavesurfers[trackIndex].current.play();
      wavesurfers[trackIndex].current.setCurrentTime(currentTime);
      }
    }
  }, []);

  const removeRegion = useCallback((trackIndex, region) => {
    region.remove();
    updateEnvelopeFromRegions();
  }, []);

  const attachListenersToRegion = useCallback((trackIndex, region) => {
    const updateAndNotify = () => {
      handleRegionChange(trackIndex);
      updateEnvelopeFromRegions();
    };

    region.on('update', updateAndNotify);
    region.on('update-end', updateAndNotify);
    region.on('remove', updateAndNotify);
    region.on('dblclick', (event) => {
      event.stopPropagation();
      removeRegion(trackIndex, region);
    });
    region.on('drag', updateEnvelopeFromRegions);
  }, [handleRegionChange, removeRegion]);

  const handleDoubleClick = useCallback((trackIndex, clickTime) => {

    stopAllTracks();

    const regions = regionsPlugins[trackIndex - 1].current;
    const existingRegions = regions.getRegions();
    const clickedRegion = Object.values(existingRegions).find(
      (region) => clickTime >= region.start && clickTime <= region.end
    );

    if (clickedRegion) {
      removeRegion(trackIndex, clickedRegion);
    } else {
      const duration = wavesurfers[trackIndex].current.getDuration();
      const start = Math.min(clickTime, duration - 1);
      const end = Math.min(start + 1, duration);

      wavesurfers[trackIndex].current.seekTo(start / duration);

      const newRegion = regions.addRegion({
        start,
        end,
        color: REGION_COLORS[trackIndex - 1],
      });

      attachListenersToRegion(trackIndex, newRegion);
      updateEnvelopeFromRegions();
      syncAllPlaybackPositions(clickTime);
    }
  }, [attachListenersToRegion, removeRegion]);

  const updateEnvelopeFromRegions = useCallback(() => {
    if (!envelopePlugin.current) return;

    const regionsTrack2 = regionsPlugins[0].current.getRegions();
    const regionsTrack3 = regionsPlugins[1].current.getRegions();

    const combinedIntervals = [
      ...Object.values(regionsTrack2).map((region) => [region.start, region.end]),
      ...Object.values(regionsTrack3).map((region) => [region.start, region.end]),
    ].sort((a, b) => a[0] - b[0]);

    const mergedIntervals = mergeIntervalsWithFades(combinedIntervals);
    const audioDuration = wavesurfers[0].current.getDuration();
    const envelopePoints = calculateVocalEnvelope(mergedIntervals, audioDuration);
    
    envelopePlugin.current.setPoints(envelopePoints);
  }, []);

  const mergeIntervalsWithFades = (intervals) => {
  const merged = [];
  
  intervals.forEach(([start, end]) => {
    if (merged.length === 0) {
      merged.push([start, end]);
    } else {
      const lastInterval = merged[merged.length - 1];
      
      if (start <= lastInterval[1] + 0.01) {
        lastInterval[1] = Math.max(lastInterval[1], end);
      } else {
        merged.push([start, end]);
      }
    }
  });

  return merged;
};

  const calculateVocalEnvelope = (mergedIntervals, audioDuration) => {
    const points = [{ time: 0, volume: 1 }];

    mergedIntervals.forEach(([start, end]) => {
      if (isFinite(start) && start > 0.05) {
        points.push({ time: Math.max(0, start - 0.05), volume: 1 });
      }

      if (isFinite(start)) {
        points.push({ time: start + 0.05, volume: 0 });
      }

      if (isFinite(end)) {
        points.push({ time: end - 0.05, volume: 0 });
      }

      if (isFinite(end) && end + 0.05 < audioDuration) {
        points.push({ time: Math.min(audioDuration, end + 0.05), volume: 1 });
      }
    });

    const lastPointTime = Math.max(0, audioDuration - 0.05);
    const lastVolume = points[points.length - 1]?.volume || 0;
    points.push({ time: lastPointTime, volume: lastVolume });

    return points;
  };

  const muteTrack = (index, mute) => {
    if (wavesurfers[index].current) {
      wavesurfers[index].current.setMuted(mute);
    }
  };

  const playAllTracks = useCallback(() => {
    wavesurfers.forEach((ws, index) => {
      if (ws.current) {
        muteTrack(index, index !== activeTrack);
        ws.current.play(currentTime);
        
        if (index > 0) {
          const regions = regionsPlugins[index - 1].current.getRegions();
          const sortedRegions = Object.values(regions).sort((a, b) => a.start - b.start);
          
          const audioProcessHandler = (time) => {
            const activeRegion = sortedRegions.find((region) => time >= region.start && time <= region.end);
            ws.current.setVolume(activeRegion ? 1 : 0);
          };
          
          ws.current.on('audioprocess', audioProcessHandler);
          ws.current.audioProcessHandler = audioProcessHandler;
        }
      }
    });
    setIsPlaying(true);
    updatePlaybackPosition();
  }, [activeTrack, currentTime, regionsPlugins, wavesurfers]);

  const pauseAllTracks = useCallback(() => {
  wavesurfers.forEach((ws, index) => {
    if (ws.current) {
      ws.current.pause();
      if (ws.current.audioProcessHandler) {
        ws.current.un('audioprocess', ws.current.audioProcessHandler);
      }
      
      // Apply volume based on current position for tracks 2 and 3
      if (index > 0) {
        const currentTime = ws.current.getCurrentTime();
        const regions = regionsPlugins[index - 1].current.getRegions();
        const activeRegion = Object.values(regions).find(
          (region) => currentTime >= region.start && currentTime <= region.end
        );
        ws.current.setVolume(activeRegion ? 1 : 0);
      }
    }
  });
  setIsPlaying(false);
  if (rafRef.current) {
    cancelAnimationFrame(rafRef.current);
  }
}, [wavesurfers, regionsPlugins]);

  const stopAllTracks = useCallback(() => {
    wavesurfers.forEach((ws) => {
      if (ws.current) {
        ws.current.stop();
        if (ws.current.audioProcessHandler) {
          ws.current.un('audioprocess', ws.current.audioProcessHandler);
        }
      }
    });
    setIsPlaying(false);
    setCurrentTime(0);
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
  }, [wavesurfers]);

  const updatePlaybackPosition = useCallback(() => {
    if (isPlaying && wavesurfers[0].current) {
      setCurrentTime(wavesurfers[0].current.getCurrentTime());
      rafRef.current = requestAnimationFrame(updatePlaybackPosition);
    }
  }, [isPlaying]);

  const handleTimelineChange = useCallback((event, newValue) => {
    const newTime = (newValue / 100) * duration;
    pauseAllTracks();
    syncAllPlaybackPositions(newTime);
  }, [duration, syncAllPlaybackPositions]);

  const handleZoom = useCallback((event, newValue) => {
    setZoom(newValue);
    wavesurfers.forEach((ws, index) => {
      if (ws.current && index !== 0) { // Skip the clean track
        // Exponential scaling for more precise control at lower zoom levels
        const zoomLevel = newValue * 10;
        ws.current.zoom(zoomLevel);
      }
    });
  }, [wavesurfers]);

  // Add a resize event listener to adjust the clean track's zoom on window resize
  useEffect(() => {
    const handleResize = () => {
      adjustCleanTrackZoom();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [adjustCleanTrackZoom]);

  const showToast = (title, message) => {
    setToast({ title, message });
    setTimeout(() => setToast(null), 3000);
  };

   const handleSubmit = async () => {
    if (!audioFile || !wavesurfersReady.every(ready => ready)) {
      showToast("Error", "No audio file uploaded or WaveSurfer instances are not ready.");
      setIsProcessing(false);
      return;
    }



    try {

      const fileToUpload = audioFile.type === 'audio/wav' || audioFile.type === 'audio/x-wav' || audioFile.type === 'audio/riff'
      ? await convertToMp3(audioFile)
      : audioFile;

    const formData = new FormData();
    formData.append('file', fileToUpload);


      const response = await fetch('https://think.philspeiser.com/cleanup/', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`API returned error: ${response.status}`);
      }

      const data = await response.json();

      if (!data.breath_intervals || !data.non_vocal_intervals) {
        throw new Error("API response is missing expected data");
      }
      
      setInitialBreathIntervals(data.breath_intervals);
      setInitialNonVocalIntervals(data.non_vocal_intervals);

      setRegionsForTrack(1, data.breath_intervals);
      setRegionsForTrack(2, data.non_vocal_intervals);

      showToast("Success", "Audio processed successfully.");
    } catch (error) {
      console.error("Error during API call:", error);
      showToast("Error", "An error occurred while processing the audio.");
    } finally {
      setIsProcessing(false);
    }
  };


  const handleRestore = () => {
    // Restore the initial results
    setRegionsForTrack(1, initialBreathIntervals);
    setRegionsForTrack(2, initialNonVocalIntervals);
    showToast("Success", "Initial results restored.");
  };

  const setRegionsForTrack = (trackIndex, intervals) => {
    const regions = regionsPlugins[trackIndex - 1].current;
    if (!regions) return;

    regions.clearRegions();

    intervals.forEach(([start, end]) => {
      regions.addRegion({
        start,
        end,
        color: REGION_COLORS[trackIndex - 1],
      });
    });

    attachRegionEventListeners(trackIndex);

    updateEnvelopeFromRegions();
  };

  const exportAudioWithEnvelope = async () => {
    if (!audioFile || !wavesurfers[0].current || !envelopePlugin.current) {
      showToast("Error", "No audio file loaded or envelope not ready.");
      return;
    }

    setIsExporting(true);

    try {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const response = await fetch(URL.createObjectURL(audioFile));
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const baseFileName = getBaseFileName(audioFile); 

      const offlineContext = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        audioBuffer.length,
        audioBuffer.sampleRate
      );

      const source = offlineContext.createBufferSource();
      source.buffer = audioBuffer;

      const gainNode = offlineContext.createGain();
      source.connect(gainNode);
      gainNode.connect(offlineContext.destination);

      const envelopePoints = envelopePlugin.current.getPoints();
      envelopePoints.forEach((point, index) => {
        const time = point.time;
        const volume = point.volume;
        gainNode.gain.setValueAtTime(volume, time);

        if (index < envelopePoints.length - 1) {
          const nextPoint = envelopePoints[index + 1];
          gainNode.gain.linearRampToValueAtTime(nextPoint.volume, nextPoint.time);
        }
      });

      source.start(0);

      const renderedBuffer = await offlineContext.startRendering();

      const wavBlob = await audioBufferToWav(renderedBuffer);
      const url = URL.createObjectURL(wavBlob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${baseFileName}_cleaned.wav`; // Updated file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      showToast("Success", "Audio exported successfully.");
    } catch (error) {
      console.error("Error exporting audio:", error);
      showToast("Error", "An error occurred while exporting the audio.");
    } finally {
      setIsExporting(false);
    }
  };


  const getBaseFileName = (file) => {
  const name = file.name;
  return name.substring(0, name.lastIndexOf('.')) || name;
};

  const exportTrackWithRegions = async (trackIndex) => {
    if (!audioFile || !wavesurfers[trackIndex].current || !regionsPlugins[trackIndex - 1].current) {
      showToast("Error", `Track ${trackIndex + 1} is not ready for export.`);
      return;
    }

    setIsExporting(true);

    try {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const response = await fetch(URL.createObjectURL(audioFile));
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const baseFileName = getBaseFileName(audioFile); // Extract the base name

      const offlineContext = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        audioBuffer.length,
        audioBuffer.sampleRate
      );

      const source = offlineContext.createBufferSource();
      source.buffer = audioBuffer;

      const gainNode = offlineContext.createGain();
      source.connect(gainNode);
      gainNode.connect(offlineContext.destination);

      const regions = regionsPlugins[trackIndex - 1].current.getRegions();
      const sortedRegions = Object.values(regions).sort((a, b) => a.start - b.start);

      gainNode.gain.setValueAtTime(0, 0);

      sortedRegions.forEach((region) => {
        const fadeInStart = Math.max(0, region.start - 0.1);
        const fadeInEnd = Math.min(audioBuffer.duration, region.start + 0.1);
        const fadeOutStart = Math.max(0, region.end - 0.1);
        const fadeOutEnd = Math.min(audioBuffer.duration, region.end + 0.1);

        gainNode.gain.setValueAtTime(0, fadeInStart);
        gainNode.gain.linearRampToValueAtTime(1, fadeInEnd);

        gainNode.gain.setValueAtTime(1, fadeOutStart);
        gainNode.gain.linearRampToValueAtTime(0, fadeOutEnd);
      });

      source.start(0);

      const renderedBuffer = await offlineContext.startRendering();

      const wavBlob = await audioBufferToWav(renderedBuffer);
      const url = URL.createObjectURL(wavBlob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      const fileNameSuffix = trackIndex === 1 ? '_breathsonly' : '_noiseonly';
    a.download = `${baseFileName}${fileNameSuffix}.wav`; // Updated file name based on the track index
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      showToast("Success", `Track ${trackIndex + 1} exported successfully.`);
    } catch (error) {
      console.error(`Error exporting track ${trackIndex + 1}:`, error);
      showToast("Error", `An error occurred while exporting track ${trackIndex + 1}.`);
    } finally {
      setIsExporting(false);
    }
  };

  const audioBufferToWav = (buffer) => {
    const interleaved = interleaveChannels(buffer);
    const dataView = encodeWAV(interleaved, buffer.sampleRate, buffer.numberOfChannels);
    return new Blob([dataView], { type: 'audio/wav' });
  };

  const interleaveChannels = (buffer) => {
    const numChannels = buffer.numberOfChannels;
    const length = buffer.length * numChannels;
    const result = new Float32Array(length);

    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numChannels; channel++) {
        result[i * numChannels + channel] = buffer.getChannelData(channel)[i];
      }
    }

    return result;
  };

  const encodeWAV = (samples, sampleRate, numChannels) => {
    const bitsPerSample = 16;
    const blockAlign = numChannels * bitsPerSample / 8;
    const byteRate = sampleRate * blockAlign;
    const dataLength = samples.length * bitsPerSample / 8;
  
    const buffer = new ArrayBuffer(44 + dataLength);
    const view = new DataView(buffer);
  
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + dataLength, true); // ChunkSize
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true); // Subchunk1Size
    view.setUint16(20, 1, true); // AudioFormat (1 = PCM)
    view.setUint16(22, numChannels, true); // Set Correct Number of Channels
    view.setUint32(24, sampleRate, true); // SampleRate
    view.setUint32(28, byteRate, true); // ByteRate
    view.setUint16(32, blockAlign, true); // BlockAlign
    view.setUint16(34, bitsPerSample, true); // BitsPerSample
    writeString(view, 36, 'data');
    view.setUint32(40, dataLength, true); // Subchunk2Size
  
    floatTo16BitPCM(view, 44, samples);
  
    return view;
  };

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const floatTo16BitPCM = (output, offset, input) => {
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  };

  const handleExport = async () => {
  setIsExporting(true);
  try {
    if (exportTrack === 3) {
      // If "All Tracks" is selected, export all tracks
      await exportAllTracks();
    } else {
      // Otherwise, export the selected single track
      if (exportTrack === 0) {
        await exportAudioWithEnvelope(); // Clean Track
      } else {
        await exportTrackWithRegions(exportTrack); // Breath or Noise Track
      }
    }
  } catch (error) {
    console.error("Export error:", error);
    showToast("Error", "Failed to export audio");
  } finally {
    setIsExporting(false);
  }
};

  const handleActiveTrackChange = (event) => {
    const newActiveTrack = parseInt(event.target.value);
    setActiveTrack(newActiveTrack);
    wavesurfers.forEach((ws, index) => {
      if (ws.current) {
        muteTrack(index, index !== newActiveTrack);
      }
    });
  };


  return (
   <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <Box sx={{ maxWidth: '4xl', margin: 'auto', p: 4 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap:2}}>
      <Typography variant="h4" gutterBottom>THE_CLEANUP</Typography>
      {initialBreathIntervals.length > 0 && (
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<RefreshIcon />} 
          onClick={handleReload}
        >
          Reload
        </Button>
       )}
       </Box>
   
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
       {initialBreathIntervals.length === 0 && (
        <Card sx={{ backgroundColor: 'background.paper' }}>
          <CardContent>
           <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography 
              variant="body1" 
              sx={{ 
               mr: 2,
               flexShrink: 1,
               minWidth: 0,
               whiteSpace: 'nowrap',
               overflow: 'hidden',
               textOverflow: 'ellipsis'
              }}
            >
              {audioFile ? `Loaded: ${audioFile.name}` : 'No audio file selected'}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUpload />}
              onClick={triggerFileInput}
              sx={{
               backgroundColor: notionBlack,
               color: magenta,
               '&:hover': {
                backgroundColor: '#2c2c2c',
               },
               whiteSpace: 'nowrap',
               flexShrink: 0,
              }}
            >
              {audioFile ? 'REPLACE AUDIO' : 'LOAD AUDIO'}
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              accept="audio/wav,audio/mpeg"
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
            {errorMessage && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {errorMessage}
        </Typography>
      )}
           </Box>
          </CardContent>
        </Card>
       )}

       {audioFile && (
        <>
          {/* Controls section */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
           {/* Play, Pause, Stop controls */}
           <Box sx={{ display: 'flex', gap: 1 }}>
            <Button 
              onClick={playAllTracks} 
              disabled={!wavesurfersReady.every(Boolean) || isPlaying} 
              startIcon={<PlayArrow />}
              variant="contained"
              size="small"
            >
              Play
            </Button>
            <Button 
              onClick={pauseAllTracks} 
              disabled={!wavesurfersReady.every(Boolean) || !isPlaying} 
              startIcon={<Pause />} 
              variant="outlined"
              size="small"
            >
              Pause
            </Button>
            <Button 
              onClick={stopAllTracks} 
              disabled={!wavesurfersReady.every(Boolean)} 
              startIcon={<Stop />} 
              variant="outlined"
              size="small"
            >
              Stop
            </Button>
           </Box>

           {/* Restore button */}
           <Button
            variant="contained"
            color="primary"
            onClick={handleRestore}
            startIcon={<Restore />}
            size="small"
           >
            Restore Initial Results
           </Button>
          </Box>

          <Box 
           sx={{ 
            width: '100%',
            overflowX: 'hidden',
            overflowY: 'visible',
           }}
          >
           {/* Clean Track */}
           <Card sx={{ marginBottom: 2, backgroundColor: 'background.paper' }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
               <Radio
                checked={activeTrack === 0}
                onChange={() => handleActiveTrackChange({ target: { value: 0 } })}
                value={0}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'Clean Track' }}
               />
               <Typography variant="h6">Clean Track</Typography>
              </Box>
              <Box sx={{ position: 'relative' }}>
               <Box 
                ref={waveformRefs[0]}
                sx={{ 
                  width: '100%', 
                  height: 100,
                  '& wave': {
                   overflowX: 'hidden !important',
                   overflowY: 'visible !important',
                  },
                  '& .wavesurfer-region': {
                   zIndex: 3,
                  },
                }} 
               />
               <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'transparent',
                  zIndex: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
               >
                <Slider
                  value={(currentTime / duration) * 100 || 0}
                  onChange={handleTimelineChange}
                  aria-labelledby="timeline-slider"
                  sx={{
                   width: '100%',
                   height: '100%',
                   zIndex: 20,
                   padding: 0,
                   '& .MuiSlider-thumb': {
                    width: 2,
                    height: '100%',
                    backgroundColor: 'transparent',
                    borderRadius: 0,
                    '&:hover, &.Mui-active': {
                      boxShadow: 'none',
                    },
                   },
                   '& .MuiSlider-rail': {
                    display: 'none',
                   },
                   '& .MuiSlider-track': {
                    display: 'none',
                   },
                  }}
                />
               </Box>
              </Box>
            </CardContent>
           </Card>

           {/* Breath Track */}
           <Card sx={{ marginBottom: 2, backgroundColor: 'background.paper' }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
               <Radio
                checked={activeTrack === 1}
                onChange={() => handleActiveTrackChange({ target: { value: 1 } })}
                value={1}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'Breath Track' }}
               />
               <Typography variant="h6">Breath Track</Typography>
              </Box>
              <Box sx={{ position: 'relative' }}>
               <Box 
                ref={waveformRefs[1]}
                sx={{ 
                  width: '100%', 
                  height: 100,
                  '& wave': {
                   overflowX: 'hidden !important',
                   overflowY: 'visible !important',
                  },
                  '& .wavesurfer-region': {
                   zIndex: 3,
                  },
                }} 
               />
              </Box>
            </CardContent>
           </Card>

           {/* Zoom slider */}
           <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 2 }}>
            <Typography variant="body2">Zoom:</Typography>
            <Slider
              value={zoom}
              onChange={handleZoom}
              min={1}
              max={50}
              step={1}
              sx={{ flexGrow: 1 }}
            />
           </Box>

           {/* Noise Track */}
           <Card sx={{ marginBottom: 2, backgroundColor: 'background.paper' }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
               <Radio
                checked={activeTrack === 2}
                onChange={() => handleActiveTrackChange({ target: { value: 2 } })}
                value={2}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'Noise Track' }}
               />
               <Typography variant="h6">Noise Track</Typography>
              </Box>
              <Box sx={{ position: 'relative' }}>
               <Box 
                ref={waveformRefs[2]}
                sx={{ 
                  width: '100%', 
                  height: 100,
                  '& wave': {
                   overflowX: 'hidden !important',
                   overflowY: 'visible !important',
                  },
                  '& .wavesurfer-region': {
                   zIndex: 3,
                  },
                }} 
               />
              </Box>
            </CardContent>
           </Card>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 2 }}>
           <FormControl sx={{ minWidth: 200, mr: 2 }}>
            <InputLabel id="export-track-label">Export Track</InputLabel>
            <Select
              label="Export Track"
              labelId="export-track-label"
              value={exportTrack}
              onChange={(e) => setExportTrack(e.target.value)}
            >
              <MenuItem value={0}>Clean Track</MenuItem>
              <MenuItem value={1}>Breath Track</MenuItem>
              <MenuItem value={2}>Noise Track</MenuItem>
              <MenuItem value={3}>All Tracks</MenuItem>
            </Select>
           </FormControl>
           <Button
            variant="contained"
            color="primary"
            onClick={handleExport}
            disabled={isExporting}
            startIcon={isExporting ? <CircularProgress size={20} /> : null}
           >
            {isExporting ? 'Exporting...' : 'Export'}
           </Button>
          </Box>
        </>
       )}
       <Typography variant="body1"sx={{
               fontSize: '0.6rem',
               mt:2
              }}>MODEL-VERSION: 10_24</Typography>
       
       <Box sx={{ height: '50px' }} /> {/* This creates the 50px empty space at the bottom */}
      </Box>
      <Snackbar open={!!toast} autoHideDuration={3000} onClose={() => setToast(null)}>
       <Alert onClose={() => setToast(null)} severity={toast?.severity || "success"} sx={{ width: '100%' }}>
        {toast?.message}
       </Alert>
      </Snackbar>
    </Box>
     <Backdrop
       sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
       open={isProcessing}
      >
       <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ mt: 2 }}>Processing audio... Please wait.</Typography>
       </Box>
      </Backdrop>
   </ThemeProvider>
  );
}
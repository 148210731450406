import { createEditor, Editor, Range, Text, Transforms, Path, last,Node } from 'slate';
import { Slate, Editable, withReact, useSlate, ReactEditor } from 'slate-react';


export const isCursorAtEndOfLine = (editor) => {
    const { selection } = editor;
  
    if (selection && Range.isCollapsed(selection)) {
      const [node] = Editor.node(editor, selection);
      if (Text.isText(node)) {
        const { text } = node;
        const { offset } = selection.anchor;
  
        // Check if the cursor is at the end of the text
        if (offset === text.length) {
          return true;
        }
  
        // Check if the next character is a line break
        if (text[offset] === '\n') {
          return true;
        }
      }
    }
  
    return false;
  };
  
  export const isLastRhymeNext = (editor, lastsetrhyme) => {
    const { selection } = editor;
    const [node] = Editor.node(editor, selection);
    const { text } = node;
    const { offset } = selection.anchor;
   //console.log('Last set rhyme:', lastsetrhyme);
   //console.log(text);
   //console.log(offset);
    const textAfterOffset = text.slice(offset);
   //console.log("Text after offset:", textAfterOffset);
  
    if (textAfterOffset === lastsetrhyme || textAfterOffset === ' ' + lastsetrhyme) {
     //console.log('SKIP THE LINE');
      return true;
    } else {
      return false;
    }
  };
  
  export async function fetchRhymes(word) {
    if (word.length <= 1) return [];
  
    try {
      const response = await fetch(`https://serve.philspeiser.com/api/rhyme?word=${word}`);
      const { rhymes } = await response.json();
     //console.log(`Rhymes for "${word}":`, rhymes);
  
      // Remove duplicates and filter out words with 2 or fewer letters
      const uniqueRhymes = Array.from(new Set(rhymes)).filter((rhyme) => rhyme.length > 2);
  
      return uniqueRhymes;
    } catch (error) {
      console.error('Error fetching rhymes:', error);
      return [];
    }
  }
  

  
  export const handleAddRhyme = (editor, rhymes, rhymeIndex, setLastsetrhyme, setShowBox,currentParagraph) => {
    const rhyme = rhymes[rhymeIndex];
    if (rhyme) {
        const textToInsert = ' ' + rhyme;
        addParagraph(editor, currentParagraph + 1, textToInsert);   
      
        
        
        setLastsetrhyme(rhyme);
        setShowBox(false);

        setTimeout(() => {      
        setCursorPosition(editor, currentParagraph +1, 0);
      } , 500);
    }
    
  };

  export async function addAILine(editor, currentParagraph, context) {
    const paragraphs = editor.children
      .filter((node) => node.type === 'paragraph' && node.children)
      .map((node) => node.children.map((child) => child.text || '').join(''))
      .filter((text) => typeof text === 'string' && text.trim() !== '');
  
    // Limit paragraphs to the specified current paragraph
    const limitedParagraphs = paragraphs.slice(0, currentParagraph + 1);
  
    try {
      const requestBody = {
        text: JSON.stringify(limitedParagraphs)
      };
  
      // Only add context to the request body if it's not an empty string
      if (context && context.trim() !== '') {
        requestBody.context = context;
      }
  
      const response = await fetch('https://serve.philspeiser.com/api/cwaddline', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      const responseData = await response.json();
      return responseData.options;
    } catch (error) {
      console.error('Error posting data to server:', error);
      return [];
    }
  }

  export const setCursorPosition = (editor, paragraph, position) => {
    try {
      // Convert to 0-based index
      const paragraphIndex = paragraph;
      const positionIndex = position;
  
      // Log the current document structure
     //console.log('Current document structure:', JSON.stringify(editor.children));
  
      // Get all paragraph nodes
      const paragraphs = editor.children.filter(node => node.type === 'paragraph');
  
     //console.log(`Total paragraphs found: ${paragraphs.length}`);
  
      // Check if the requested paragraph exists
      if (paragraphIndex < -1) {
        console.warn(`Paragraph ${paragraph} does not exist. Document has ${paragraphs.length} paragraphs.`);
        return false;
      }
  
      const node = paragraphs[paragraphIndex];
      const path = [paragraphIndex];
  
      // Get the text content of the paragraph
      const text = Node.string(node);
  
     //console.log(`Content of paragraph ${paragraph}:`, text);
  
      // Check if the requested position exists
      if (positionIndex < 0 || positionIndex > text.length) {
        console.warn(`Position ${position} is out of range. Paragraph has ${text.length} characters.`);
        return false;
      }
  
      // Create a new point at the specified position
      const point = { path: [...path, 0], offset: positionIndex };
  
      // Set the selection to the new point
      Transforms.select(editor, point);
  
      // Ensure the editor is focused
      ReactEditor.focus(editor);
  
     //console.log(`Cursor set to paragraph ${paragraph}, position ${position}`);
      return true;
    } catch (error) {
      console.error('Error setting cursor position:', error);
      return false;
    }
  };


  export const addParagraph = (editor, position, text) => {
    try {
      // Convert to 0-based index
      const insertIndex = position;
  
      // Get all paragraph nodes
      const paragraphs = editor.children.filter(node => node.type === 'paragraph');
  
     //console.log(`Total paragraphs before insertion: ${paragraphs.length}`);
  
      // Check if the position is valid
      if (insertIndex < 0 || insertIndex > paragraphs.length) {
        console.warn(`Invalid position ${position}. Valid range is 1 to ${paragraphs.length + 1}.`);
        return false;
      }
  
      // Create the new paragraph node
      const newParagraph = {
        type: 'paragraph',
        children: [{ text: text }],
      };
  
      // Insert the new paragraph
      Transforms.insertNodes(editor, newParagraph, { at: [insertIndex] });
  
     //console.log(`New paragraph inserted at position ${position} with text: "${text}"`);
     //console.log('Total paragraphs after insertion:', editor.children.length);
  
      return true;
    } catch (error) {
      console.error('Error adding paragraph:', error);
      return false;
    }
  };


  export const replaceParagraphText = (editor, paragraph, text) => {
    try {
      // Convert to 0-based index
      const paragraphIndex = paragraph;
  
      // Get all paragraph nodes
      const paragraphs = editor.children.filter(node => node.type === 'paragraph');
  
     //console.log(`Total paragraphs: ${paragraphs.length}`);
  
      // Check if the paragraph exists
      if (paragraphIndex < -1) {
        console.warn(`Paragraph ${paragraph} does not exist. Valid range is 1 to ${paragraphs.length}.`);
        return false;
      }
  
      // Get the path to the paragraph
      const path = [paragraphIndex];
  
      // Replace the text in the paragraph
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, path),
          focus: Editor.end(editor, path),
        },
      });
      Transforms.insertText(editor, text, { at: path });
  
     //console.log(`Text replaced in paragraph ${paragraph} with: "${text}"`);
  
      return true;
    } catch (error) {
      console.error('Error replacing paragraph text:', error);
      return false;
    }
  };


  export const deleteParagraph = (editor, paragraphNumber) => {
    try {
      
      const paragraphIndex = paragraphNumber;
  
      // Get all paragraph nodes
      const paragraphs = editor.children.filter(node => node.type === 'paragraph');
  
     //console.log(`Total paragraphs before deletion: ${paragraphs.length}`);
  
      // Check if the paragraph exists
      if (paragraphIndex < -1) {
        console.warn(`Paragraph ${paragraphNumber} does not exist. Valid range is 1 to ${paragraphs.length}.`);
        return false;
      }
  
      // Delete the paragraph
      Transforms.removeNodes(editor, {
        at: [paragraphIndex],
        match: n => n.type === 'paragraph',
      });
  
     //console.log(`Paragraph ${paragraphNumber} deleted.`);
     //console.log(`Total paragraphs after deletion: ${editor.children.length}`);
  
      return true;
    } catch (error) {
      console.error('Error deleting paragraph:', error);
      return false;
    }
  };

  export const getBoxPosition = (editor, paragraphNumber) => {
    try {
      const paragraphIndex = paragraphNumber ;
  
      // Get all paragraph nodes
      const paragraphs = editor.children.filter(node => node.type === 'paragraph');
  
      const paragraph = paragraphs[paragraphIndex];
      const path = [paragraphIndex];
  
      // Find the last non-empty word in the paragraph
      const text = paragraph.children.map(n => n.text).join('');
      const words = text.trim().split(/\s+/);
      if (words.length === 0) return null;
  
      const lastWord = words[words.length - 1];
      const lastWordIndex = text.lastIndexOf(lastWord);
  
      if (lastWordIndex === -1) return null;
  
      // Create a range for the position after the last word
      const range = {
        anchor: { path: [...path, 0], offset: lastWordIndex + lastWord.length },
        focus: { path: [...path, 0], offset: lastWordIndex + lastWord.length },
      };
  
      // Get the DOM range for this position
        const domRange = ReactEditor.toDOMRange(editor, range);
        const rect = domRange.getBoundingClientRect();

        // Calculate the position, considering scroll offsets
        const x = Math.min(rect.right + window.scrollX + 20 , window.innerWidth - 150);

        const y = rect.top + window.scrollY - 5;

       //console.log(`Position behind last word in paragraph ${paragraphNumber}: x=${x}, y=${y}`);

        return { x, y };

    } catch (error) {
      console.error('Error getting position behind last word:', error);
      return null;
    }
  };


  export const getLinePosition = (editor, paragraphNumber) => {
    try {
      const paragraphIndex = paragraphNumber;
      // Get all paragraph nodes
      const paragraphs = editor.children.filter(node => node.type === 'paragraph');
      const paragraph = paragraphs[paragraphIndex];
      
      if (!paragraph) return null;
  
      const path = [paragraphIndex];
  
      const range = {
        anchor: { path: [...path, 0], offset: 0 },
        focus: { path: [...path, 0],  offset: 0 },
      };
  
      // Get the DOM range for this position
      const domRange = ReactEditor.toDOMRange(editor, range);
      const rect = domRange.getBoundingClientRect();
      const y = rect.top + window.scrollY;
  
      return y;
    } catch (error) {
      console.error('Error getting position', error);
      return null;
    }
  };

  export const getParagraphText = (editor, paragraphNumber) => {
    try {
      // Convert to 0-based index
      const paragraphIndex = paragraphNumber;
  
      // Get all paragraph nodes
      const paragraphs = editor.children.filter(node => node.type === 'paragraph');
  
      // Check if the paragraph exists
      if (paragraphIndex < -1) {
        console.warn(`Paragraph ${paragraphNumber} does not exist. Valid range is 1 to ${paragraphs.length}.`);
        return null;
      }
  
      const paragraph = paragraphs[paragraphIndex];
  
      // Get the text content of the paragraph
      const text = Node.string(paragraph);
  
     //console.log(`Text content of paragraph ${paragraphNumber}:`, text);
  
      return text;
    } catch (error) {
      console.error('Error getting paragraph text:', error);
      return null;
    }
  };
  

  export const getLastWordofLine = (editor, paragraphNumber) => {
    try {
      // Convert to 0-based index
      const paragraphIndex = paragraphNumber;
  
      // Get all paragraph nodes
      const paragraphs = editor.children.filter(node => node.type === 'paragraph');
  
      // Check if the paragraph exists
      if (paragraphIndex < 0 || paragraphIndex >= paragraphs.length) {
        console.warn(`Paragraph ${paragraphNumber} does not exist. Valid range is 0 to ${paragraphs.length - 1}.`);
        return null;
      }
  
      const paragraph = paragraphs[paragraphIndex];
  
      // Get the text content of the paragraph
      const text = Node.string(paragraph);
  
      // Extract the last word
      const words = text.trim().split(/\s+/);
      const lastWord = words[words.length - 1] || '';

      const cleanword = lastWord.replace(/[.,\/#!$%\^&\*;:{}=_`~()?]/g, "");
  
    
      return cleanword;
    } catch (error) {
      console.error('Error getting last word of paragraph:', error);
      return null;
    }
  };




  export const isCursorBehindLastWord = (editor, paragraphNumber) => {
    try {
      const paragraphIndex = paragraphNumber;
  
      // Get all paragraph nodes
      const paragraphs = editor.children.filter(node => node.type === 'paragraph');
  
      // Check if the paragraph exists
      if (paragraphIndex < 0 || paragraphIndex >= paragraphs.length) {
        console.warn(`Paragraph ${paragraphNumber} does not exist. Valid range is 0 to ${paragraphs.length - 1}.`);
        return false;
      }
  
      const paragraph = paragraphs[paragraphIndex];
      const { selection } = editor;
  
      if (!selection || !Range.isCollapsed(selection)) {
        return false;
      }
  
      // Get the text content of the paragraph
      const paragraphText = Node.string(paragraph);
      const words = paragraphText.trim().split(/\s+/);
  
      if (words.length === 0) {
        return false;
      }
  
      const lastWord = words[words.length - 1];
      const lastWordIndex = paragraphText.lastIndexOf(lastWord);
  
      // Get the text node and offset within the paragraph
      const [node, nodePath] = Editor.node(editor, [paragraphIndex, 0]);
  
      if (!Text.isText(node)) {
        return false;
      }
  
      // Get the offset within the text node
      const offset = selection.anchor.offset;

      const cleanword = lastWord.replace(/[.,\/#!$%\^&\*;:{}=_`~()?]/g, "");
  
      // Check if the cursor is right after the last word
      return {status:offset === lastWordIndex + lastWord.length,lastWord:cleanword};
    } catch (error) {
      console.error('Error checking cursor position:', error);
      return false;
    }
  };




  export const replaceEditorContent = (editor, newContent) => {
    try {
      // Log the new content for debugging
     //console.log('New content:', JSON.stringify(newContent, null, 2));
  
      // Clear the editor content
      editor.children = [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ];
      editor.selection = null;
      editor.marks = null;
      editor.operations = [];

     //console.log('newcontent children:', newContent.children);
  
      // Replace the editor's content with newContent
      editor.children = newContent.children;
  
      // Notify the editor of the change
      editor.onChange();
  
     //console.log('Editor content replaced successfully');
      return true;
  } catch (error) {
    console.error('Error replacing editor content:', error);
    return false;
  }
};





export function clearEditorContent(editor) {
  editor.children = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];
  editor.selection = null;
  editor.marks = null;
  editor.operations = [];
  editor.onChange();
}
"use client"

import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
  LinearProgress,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { CloudUpload, CloudDownload, MusicNote, Speed, LibraryMusic } from '@mui/icons-material';
import '@fontsource/oswald';
import MidiPlayer from './midiplayer';
import WaveformDisplay from './WaveformDisplay';
import transcriptionToMidi from './transcriptionToMidi'; 
import * as Tone from 'tone';
import { Refresh as RefreshIcon } from '@mui/icons-material';

const notionBlack = '#191919';
const notionGrey = '#202020';
const offWhite = '#CFCFCF';
const magenta = '#FF00FF';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: offWhite,
    },
    secondary: {
      main: notionBlack,
    },
    background: {
      default: notionBlack,
      paper: notionGrey,
    },
    text: {
      primary: offWhite,
      secondary: offWhite,
    },
  },
  typography: {
    fontFamily: 'Oswald, Arial, sans-serif',
    h4: {
      fontWeight: 500,
      color: offWhite,
    },
    h6: {
      fontWeight: 400,
      color: offWhite,
    },
    button: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: offWhite,
          backgroundColor: notionGrey,
          '&:hover': {
            backgroundColor: '#2c2c2c',
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: notionBlack,
            color: offWhite,
            '&:hover': {
              backgroundColor: magenta,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: offWhite,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#2c2c2c',
          },
          '&.Mui-selected': {
            backgroundColor: magenta,
            color: notionBlack,
            '&:hover': {
              backgroundColor: '#FF40FF',
            },
          },
        },
      },
    },
  },
});

const SUPPORTED_FILE_TYPES = ['audio/mpeg', 'audio/wav'];
const MAX_FILE_SIZE = 80 * 1024 * 1024; // 80MB in bytes



export default function MidiTranscription() {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [bpm, setBpm] = useState(null);
  const [key, setKey] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [chords, setChords] = useState([]);
  const fileInputRef = useRef(null);
  const [audioFile, setAudioFile] = useState(null);
  const [regionStart, setRegionStart] = useState(0);
  const [regionEnd, setRegionEnd] = useState(0);
  const [onsetThreshold, setOnsetThreshold] = useState(0.5);
  const [minlength, setminlength] = useState(0.2);
  const [confidenceThreshold, setConfidenceThreshold] = useState(0.2);
  const [transcriptionResult, setTranscriptionResult] = useState(null);
  const [chordOnsets, setChordOnsets] = useState([]);
  const [userBpm, setUserBpm] = useState('');
  const [prettychord, setPrettychord] = useState(false);
  const midiPlayerRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const handleReload = () => {
    window.location.reload();
  };

  const validateFile = (file) => {
    if (!file) {
      setErrorMessage('Please select a file.');
      return false;
    }
  
    const fileName = file.name.toLowerCase();
    const fileExtension = fileName.split('.').pop();
    const isWav = fileExtension === 'wav';
    const isMp3 = fileExtension === 'mp3';
  
    if (!isWav && !isMp3) {
      setErrorMessage('Unsupported file type. Please upload a WAV or MP3 file.');
      return false;  // Fixed: return false instead of undefined
    }
  
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage('File size exceeds 80MB limit. Please choose a smaller file.');
      return false;
    }
  
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function(e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 12); // Read 12 bytes instead of 4
        let header = "";
        for(let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        
        // Check file signatures
        if (isWav && header.startsWith('52494646')) {  // "RIFF"
          resolve(true); // Valid WAV
        } else if (isMp3 && (header.startsWith('4944') || header.startsWith('fffb'))) {  // ID3 or MPEG frame sync
          resolve(true); // Valid MP3
        } else {
          setErrorMessage('The file appears to be corrupted or unreadable.');
          resolve(false);
        }
      };
  
      reader.onerror = function() {
        setErrorMessage('Error reading the file. It may be corrupted.');
        resolve(false);
      };
  
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setErrorMessage(null); // Clear any previous error message
    
    if (file) {
      const isValid = await validateFile(file);
      if (isValid) {
        setAudioFile(file);
        setToast({ severity: 'success', message: 'File validated successfully.' });
      }
    }
  };

  const handleSetRegionStart = useCallback((start) => {
    setRegionStart(start);
  }, []);

  const handleSetRegionEnd = useCallback((end) => {
    setRegionEnd(end);
  }, []);

  const handleChordOnsetsChange = useCallback((newChordOnsets) => {
    setChordOnsets(newChordOnsets);
    //console.log('Chord onsets:', newChordOnsets);
  }, []);

  // Memoize the transcription result
  const memoizedTranscriptionResult = useMemo(() => transcriptionResult, [transcriptionResult]);

  const handleOnsetThresholdChange = (event, newValue) => {
    setOnsetThreshold(newValue);
    if (transcriptionResult) {
      updateMidi(transcriptionResult, newValue, confidenceThreshold, chordOnsets, userBpm, prettychord);
    }
  };

  const handleminlength = (event, newValue) => {
    setminlength(newValue);
    if (transcriptionResult) {
      updateMidi(transcriptionResult, newValue, confidenceThreshold, chordOnsets, userBpm, prettychord);
    }
  };

  const handleConfidenceThresholdChange = (event, newValue) => {
    setConfidenceThreshold(newValue);
    if (transcriptionResult) {
      updateMidi(transcriptionResult, onsetThreshold, newValue, chordOnsets, userBpm, prettychord);
    }
  };

  const handlePrettychordChange = (event) => {
    const isChecked = event.target.checked;
    setPrettychord(isChecked);

    if (transcriptionResult) {
      updateMidi(transcriptionResult, onsetThreshold, confidenceThreshold, chordOnsets, userBpm, isChecked);
    }
  };

  const handleUserBpmChange = (event) => {
    setUserBpm(event.target.value);
    if (transcriptionResult) {
      updateMidi(transcriptionResult, onsetThreshold, confidenceThreshold, chordOnsets, event.target.value, prettychord);
    }
  };

  const updateMidi = (result, onsetThresh, confidenceThresh, currentChordOnsets, overrideBpm, prettychord) => {
    // Filter notes based on confidence threshold
    const filteredNotes = result.note_events.filter(note => note.confidence >= confidenceThresh);
    const updatedResult = { ...result, note_events: filteredNotes, bpm: { bpm: overrideBpm || result.bpm.bpm } };
  
    const { midi, exposedObject } = transcriptionToMidi(updatedResult, onsetThresh, currentChordOnsets, prettychord, minlength);
    const midiArrayBuffer = midi.toArray();
    const midiBlob = new Blob([midiArrayBuffer], { type: 'audio/midi' });
    const midiUrl = URL.createObjectURL(midiBlob);
    
    if (midiPlayerRef.current) {
      //console.log('Stopping previous playback');
      midiPlayerRef.current.stop();
    }

    setDownloadUrl(midiUrl);
    
    // Set chords and scale to state
    setChords(exposedObject.chords);
    setKey(exposedObject.scale);
  };

  useEffect(() => {
    if (transcriptionResult && chordOnsets.length > 0) {
      updateMidi(transcriptionResult, onsetThreshold, confidenceThreshold, chordOnsets, userBpm, prettychord);
    }
  }, [chordOnsets, transcriptionResult, onsetThreshold, confidenceThreshold, userBpm, prettychord]);

  const handleTranscription = async () => {
    if (!audioFile) {
      setToast({ severity: 'error', message: 'Please select an audio file first.' });
      return;
    }
  
    setIsUploading(true);
    setUploadProgress(0);
    setDownloadUrl(null);
    setBpm(null);
    setKey(null);
    setChords([]); 

    try {
      // Process the audio file with the selected region, adding 4 seconds before and after
      const processedAudio = await processAudioFile(audioFile, regionStart, regionEnd);

      const formData = new FormData();
      formData.append('file', processedAudio, 'processed_audio.wav');

      const fileType = 'wav';
      const apiUrl = `https://think.philspeiser.com/transcribemidi/tomidi`;

      const xhr = new XMLHttpRequest();
      xhr.open('POST', apiUrl);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setUploadProgress(percentCompleted);
          if (percentCompleted === 100) {
            setIsUploading(false);
            setIsProcessing(true);
          }
        }
      };

      xhr.onload = () => {
        setIsProcessing(false);

        if (xhr.status === 200) {
          try {
            const result = JSON.parse(xhr.responseText);
            //console.log('Server response:', result);

            // Store the full transcription result
            setTranscriptionResult(result);

            // Set BPM (use user-defined BPM if available)
            const detectedBpm = userBpm ? Number(userBpm) : result.bpm.bpm;
            setBpm(detectedBpm);
            setUserBpm(detectedBpm);

            // Generate MIDI file using the current threshold and BPM
            updateMidi(result, onsetThreshold, confidenceThreshold, chordOnsets, detectedBpm, prettychord);

            setToast({ severity: 'success', message: 'Transcription complete! MIDI file is ready for playback.' });
          } catch (parseError) {
            console.error('Error parsing response:', parseError);
            setToast({ severity: 'error', message: 'Error parsing server response. Please try again.' });
          }
        } else {
          console.error('Server error:', xhr.status, xhr.responseText);
          setToast({ severity: 'error', message: `Server error: ${xhr.status}. Please try again.` });
        }
      };

      xhr.send(formData);
    } catch (error) {
      console.error('Error:', error);
      setToast({ severity: 'error', message: error.message || 'An error occurred during transcription.' });
      setIsUploading(false);
      setIsProcessing(false);
    }
  };

  // Function to process mono and stereo audio files
  const processAudioFile = async (audioFile, start, end) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        try {
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          const numberOfChannels = audioBuffer.numberOfChannels;
          const sampleRate = audioBuffer.sampleRate;

          // Convert start and end times to sample indices
          const startSample = Math.max(0, Math.floor(start * sampleRate));
          const endSample = Math.min(audioBuffer.length, Math.floor(end * sampleRate));

          // Create a new buffer for the trimmed audio
          const newBufferLength = endSample - startSample;
          const trimmedBuffer = audioContext.createBuffer(
            numberOfChannels,
            newBufferLength,
            sampleRate
          );

          // Copy the selected portion of the audio to the new buffer
          for (let channel = 0; channel < numberOfChannels; channel++) {
            const channelData = audioBuffer.getChannelData(channel);
            const trimmedData = trimmedBuffer.getChannelData(channel);

            // Copy the selected audio region
            for (let i = 0; i < newBufferLength; i++) {
              trimmedData[i] = channelData[i + startSample];
            }
          }

          // Convert the trimmed buffer to a WAV Blob
          const wavBlob = audioBufferToWav(trimmedBuffer);
          resolve(wavBlob);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (err) => reject(err);
      reader.readAsArrayBuffer(audioFile);
    });
  };

  // Function to convert AudioBuffer to a WAV Blob
  const audioBufferToWav = (buffer) => {
    const numOfChannels = buffer.numberOfChannels;
    const length = buffer.length * numOfChannels * 2 + 44;
    const sampleRate = buffer.sampleRate;

    const wavBuffer = new ArrayBuffer(length);
    const view = new DataView(wavBuffer);

    // Write WAV header
    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    let offset = 0;

    // RIFF chunk descriptor
    writeString(view, offset, 'RIFF'); offset += 4;
    view.setUint32(offset, 36 + buffer.length * numOfChannels * 2, true); offset += 4;
    writeString(view, offset, 'WAVE'); offset += 4;

    // FMT sub-chunk
    writeString(view, offset, 'fmt '); offset += 4;
    view.setUint32(offset, 16, true); offset += 4; // SubChunk1Size (16 for PCM)
    view.setUint16(offset, 1, true); offset += 2; // Audio format (1 = PCM)
    view.setUint16(offset, numOfChannels, true); offset += 2; // NumChannels
    view.setUint32(offset, sampleRate, true); offset += 4; // SampleRate
    view.setUint32(offset, sampleRate * numOfChannels * 2, true); offset += 4; // ByteRate
    view.setUint16(offset, numOfChannels * 2, true); offset += 2; // BlockAlign
    view.setUint16(offset, 16, true); offset += 2; // BitsPerSample

    // Data sub-chunk
    writeString(view, offset, 'data'); offset += 4;
    view.setUint32(offset, buffer.length * numOfChannels * 2, true); offset += 4;

    // Write the PCM samples
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numOfChannels; channel++) {
        let sample = buffer.getChannelData(channel)[i];
        sample = Math.max(-1, Math.min(1, sample));
        view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
        offset += 2;
      }
    }

    return new Blob([view], { type: 'audio/wav' });
  };


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ maxWidth: '4xl', margin: 'auto', p: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>THE_TRANSCRIPT</Typography>
          {transcriptionResult && (
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<RefreshIcon />} 
              onClick={handleReload}
            >
              Reload
            </Button>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
        <Card sx={{ backgroundColor: 'background.paper' , mb:1}}>   
            <CardContent>
              <WaveformDisplay
                audioFile={audioFile}
                regionStart={regionStart}
                setRegionStart={handleSetRegionStart}
                regionEnd={regionEnd}
                setRegionEnd={handleSetRegionEnd}
                transcriptionResult={memoizedTranscriptionResult}
                onChordOnsetsChange={handleChordOnsetsChange}
                bpm={userBpm || (transcriptionResult && transcriptionResult.bpm && transcriptionResult.bpm.bpm)}
                visible={!!audioFile && !!transcriptionResult}
              />
               
  
              {chords.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Box sx={{ display: 'flex', width: '100%', mt: 2 }}>
                    {chords.map((chord, index) => {
                      const widthPercentage = ((chords[index + 1]?.intervalStart || regionEnd) - chord.intervalStart) / regionEnd * 100;
                      const modifiedChordName = chord.name.replace(/M/g, 'm');
                      return (
                        <Box
                          key={index}
                          sx={{
                            width: `${widthPercentage}%`,
                            position: 'relative',
                            textAlign: 'center',
                          }}
                        >
                          {modifiedChordName}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}

</CardContent>
</Card>
  
              {errorMessage && <Typography color="error" sx={{ mt: 0 , mb: 1}}>{errorMessage}</Typography>}
              {isUploading && (
                <Box sx={{ width: '100%', mt: 2 }}>
                  <LinearProgress variant="determinate" value={uploadProgress} />
                  <Typography variant="body2" color="text.secondary" align="center">
                    Uploading: {uploadProgress}%
                  </Typography>
                </Box>
              )}
      
  
          {downloadUrl && (
            <MidiPlayer ref={midiPlayerRef} downloadUrl={downloadUrl} initialBpm={userBpm || (transcriptionResult && transcriptionResult.bpm && transcriptionResult.bpm.bpm)} />
          )}
  
          <Card sx={{ backgroundColor: 'background.paper', mt: 0, p: 1 }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2,flexWrap:'wrap' }}>
              {!transcriptionResult && (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      mr: 2,
                      flexShrink: 1,
                      minWidth: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {audioFile ? `Loaded: ${audioFile.name}` : 'No audio file selected'}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUpload />}
                    onClick={triggerFileInput}
                    disabled={isUploading || isProcessing}
                    sx={{
                      backgroundColor: notionBlack,
                      color: magenta,
                      '&:hover': {
                        backgroundColor: '#2c2c2c',
                      },
                      whiteSpace: 'nowrap',
                      flexShrink: 0,
                    }}
                  >
                    {audioFile ? 'REPLACE AUDIO' : 'LOAD AUDIO'}
                  </Button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="audio/wav,audio/mpeg"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                </>
              )}
  
              <TextField
                type="number"
                value={userBpm}
                onChange={handleUserBpmChange}
                placeholder="BPM"
                inputProps={{ min: 1, max: 200 }}
                sx={{
                  width: '150px',
                  height: '40px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: offWhite,
                    },
                    '&:hover fieldset': {
                      borderColor: magenta,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: magenta,
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: offWhite,
                    padding: '8px 14px',
                  },
                }}

                
              />
              <Typography
                    variant="body1"
                    sx={{
                      mr: 0,
                      flexShrink: 0,
                      minWidth: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      fontSize: '0.7rem',
                    }}
                  >
                    {transcriptionResult ? `` : '(Please provide BPM if known, leave empty to use auto-detect)'}
                  </Typography>
            


  
              {!transcriptionResult && (
                <Button
                  variant="contained"
                  onClick={handleTranscription}
                  disabled={!audioFile || isUploading || isProcessing}
                  sx={{
                    backgroundColor: notionGrey,
                    color: offWhite,
                    minWidth: '220px',
                    '&:hover': {
                      backgroundColor: '#2c2c2c',
                    },
                    '&:disabled': {
                      backgroundColor: '#1a1a1a',
                      color: '#666666',
                    },
                  }}
                >
                  {isUploading ? 'Uploading...' : isProcessing ? 'Processing...' : 'Transcribe MIDI'}
                </Button>
              )}
  
              {transcriptionResult && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={prettychord}
                        onChange={handlePrettychordChange}
                        color="primary"
                      />
                    }
                    label="Pretty Chord"
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="body2">Note Filter</Typography>
                    <Slider
                      value={minlength}
                      onChange={handleminlength}
                      aria-labelledby="minlength-slider"
                      valueLabelDisplay="auto"
                      step={0.01}
                      min={0}
                      max={1}
                      sx={{
                        width: '200px',
                        color: magenta,
                        '& .MuiSlider-thumb': {
                          backgroundColor: magenta,
                        },
                        '& .MuiSlider-track': {
                          backgroundColor: magenta,
                        },
                        '& .MuiSlider-rail': {
                          backgroundColor: offWhite,
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="body2">Selectivity</Typography>
                    <Slider
                      value={confidenceThreshold}
                      onChange={handleConfidenceThresholdChange}
                      aria-labelledby="confidence-threshold-slider"
                      valueLabelDisplay="auto"
                      step={0.01}
                      min={0}
                      max={1}
                      sx={{
                        width: '200px',
                        color: magenta,
                        '& .MuiSlider-thumb': {
                          backgroundColor: magenta,
                        },
                        '& .MuiSlider-track': {
                          backgroundColor: magenta,
                        },
                        '& .MuiSlider-rail': {
                          backgroundColor: offWhite,
                        },
                      }}
                    />
                  </Box>
                </>
              )}
  
              {key && (
                <Typography variant="body2" sx={{ ml: 2 }}>
                  Detected Scale: {key}
                </Typography>
              )}
            </CardContent>
          </Card>
          <Typography variant="body1"sx={{
               fontSize: '0.6rem',
               mt:2
              }}>MODEL-VERSION: 10_24</Typography>
          <Box sx={{ height: '50px'}} /> {/* This creates the 50px empty space at the bottom */}
        </Box>
  
        <Snackbar open={!!toast} autoHideDuration={6000} onClose={() => setToast(null)}>
          <Alert onClose={() => setToast(null)} severity={toast?.severity || "success"} sx={{ width: '100%' }}>
            {toast?.message}
          </Alert>
        </Snackbar>
  
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isProcessing}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress color="inherit" />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Processing audio... Please wait.
            </Typography>
          </Box>
        </Backdrop>
      </Box>
    </ThemeProvider>
  );
  
  }

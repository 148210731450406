'use client'
import React, { useState, useEffect, useRef } from 'react'
import { Button, TextField, Typography, Box, Paper, CircularProgress } from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'


const notionBlack = '#191919'
const notionGrey = '#202020'
const offWhite = '#CFCFCF'
const magenta = '#FF00FF'

export default function TheCB({ contextAttachment }) {
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMedia, setLoadingMedia] = useState({})
  const messagesEndRef = useRef(null)
  const lastMessageRef = useRef(null)

  const scrollToLastMessage = () => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  useEffect(scrollToLastMessage, [messages])

  React.useEffect(() => {
    if (contextAttachment) {
      setMessages([])
    }

    // Initialize with greeting message
    const user = JSON.parse(localStorage.getItem('user'))
    const displayName = user?.displayName || 'there'
    const firstName = displayName.split(' ')[0]
    
    const initialMessage = {
      role: 'assistant',
      type: 'text',
      content: `Hey ${firstName},\nI finished listening to your song. Let's talk about it!\nWhat do you want to discuss about it?`
    }
    
    setMessages([initialMessage])
    
  }, [contextAttachment])

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!input.trim()) return;
  
    const userMessage = { role: 'user', type: 'text', content: input };
    setMessages((prev) => [...prev, userMessage]);
  
    setInput('');
    setIsLoading(true);
  
    try {
      const messageHistory = messages
        .filter((message) => message.role === 'user' || message.role === 'assistant')
        .map((message) => ({
          role: message.role,
          content: message.content,
        }));
  
      const user = JSON.parse(localStorage.getItem('user'));
      const firstName = user?.displayName?.split(' ')[0] || 'User';
          
      const payload = {
        question: input,
        context: contextAttachment ? contextAttachment.content : '',
        message_history: messageHistory,
        user_name: firstName
      };
  
      const response = await fetch('https://think.philspeiser.com/talk/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      let assistantMessage = { role: 'assistant', type: 'text', content: '' };
      setMessages((prev) => [...prev, assistantMessage]);
  
      let fullContent = '';

      const updateAssistantMessage = (chunk) => {
        fullContent += chunk;
        
        // Simple de-duplication: remove exact repetitions of the last 50 characters
        const lastChars = fullContent.slice(-50);
        const dupeIndex = fullContent.slice(0, -50).lastIndexOf(lastChars);
        if (dupeIndex !== -1) {
          fullContent = fullContent.slice(0, dupeIndex) + fullContent.slice(-50);
        }

        setMessages((prev) => {
          const newMessages = [...prev];
          const lastMessageIndex = newMessages.length - 1;
          newMessages[lastMessageIndex].content = fullContent;
          return newMessages;
        });
      };
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value, { stream: true });
        updateAssistantMessage(chunk);
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = 'Sorry, there was an error processing your request.';
      setMessages((prev) => [...prev, { role: 'assistant', type: 'text', content: errorMessage }]);
    } finally {
      setIsLoading(false);
    }
  };
  

  const renderMessage = (message, index, isLastMessage) => {
    const messageContent = (
      <Box
        ref={isLastMessage ? lastMessageRef : null}
        key={index}
        sx={{
          mb: 2,
          textAlign: message.role === 'user' ? 'right' : 'left',
        }}
      >
        <Box
          sx={{
            display: 'inline-block',
            p: 1,
            borderRadius: 1,
            backgroundColor: message.role === 'user' ? notionGrey : 'secondary.main',
            color: 'text.primary',
          }}
        >
          {(() => {
            switch (message.type) {
              case 'text':
                return (
                  <ReactMarkdown
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                          <SyntaxHighlighter
                            style={dark}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                          >
                            {String(children).replace(/\n$/, '')}
                          </SyntaxHighlighter>
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        )
                      },
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                )
              case 'image':
                return (
                  <>
                    {loadingMedia[message.content] && <CircularProgress size={24} />}
                    <img
                      src={message.content}
                      alt="Assistant's image"
                      onLoad={() => setLoadingMedia((prev) => ({ ...prev, [message.content]: false }))}
                      style={{ display: loadingMedia[message.content] ? 'none' : 'block', maxWidth: '100%' }}
                    />
                  </>
                )
              case 'file':
                return <a href={message.content} download>Download file</a>
              default:
                return <Typography>{message.content}</Typography>
            }
          })()}
        </Box>
      </Box>
    )

    return messageContent
  }

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      height: '100%', // Take full height of parent container
      maxWidth: '4xl',
      margin: 'auto',
      p: 2,
    }}>
      <Paper
        elevation={0}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          p: 2,
          mb: 2,
          backgroundColor: '#191919',
        }}
      >
        {messages.map((message, index) => 
          renderMessage(message, index, index === messages.length - 1)
        )}
        <div ref={messagesEndRef} />
      </Paper>
      <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'background.default', pt: 2 }}>
        <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '8px' }}>
          <TextField
            fullWidth
            variant="outlined"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message here..."
            disabled={isLoading}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{
              backgroundColor: 'background.paper',
              color: 'text.primary',
              '&:hover': {
                backgroundColor: '#2c2c2c',
              },
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
          </Button>
        </form>
      </Box>
    </Box>
  )
}
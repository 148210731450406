import { useEffect, useState } from 'react'

const isKeyboardInput = (elem) =>
  (elem.tagName === 'INPUT' &&
    !['button', 'submit', 'checkbox', 'file', 'image'].includes(elem.type)) ||
  elem.hasAttribute('contenteditable')

const useIsOnScreenKeyboardOpen = () => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const handleFocusIn = (e) => {
      if (!e.target) return;
      const target = e.target;
      if (isKeyboardInput(target)) {
        setOpen(true)
      }
    }

    const handleFocusOut = (e) => {
      if (!e.target) return;
      const target = e.target;
      if (isKeyboardInput(target)) {
        setOpen(false)
      }
    }

    document.addEventListener('focusin', handleFocusIn)
    document.addEventListener('focusout', handleFocusOut)

    return () => {
      document.removeEventListener('focusin', handleFocusIn)
      document.removeEventListener('focusout', handleFocusOut)
    }
  }, [])

  return isOpen
}

// Make sure to use a default export
export default useIsOnScreenKeyboardOpen
import { createTheme } from '@mui/material/styles';
import { height } from 'tonal';

export const notionBlack = '#191919';
export const notionGrey = '#202020';
export const offWhite = '#CFCFCF';
export const magenta = '#FF00FF';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: offWhite,
    },
    secondary: {
      main: notionBlack,
    },
    background: {
      default: notionBlack,
      paper: notionGrey,
    },
    text: {
      primary: offWhite,
      secondary: offWhite,
    },
  },
  typography: {
    fontFamily: 'Oswald, Arial, sans-serif',
    h4: {
      fontWeight: 500,
      color: offWhite,
    },
    h6: {
      fontWeight: 400,
      color: offWhite,
    },
    button: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: offWhite,
          backgroundColor: notionGrey,
          '&:hover': {
            backgroundColor: '#2c2c2c',
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: notionBlack,
            color: offWhite,
            '&:hover': {
              backgroundColor: magenta,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
  },
});

const editorStyle = {
  slateEditor: {
    backgroundColor: notionGrey,
    color: 'white',
    fontFamily: 'monospace',
    fontSize: '1.0rem',
    padding: '20px',
    border: 'none',
    outline: 'none',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    height: '100%',
  },
};

const boxStyle = {
  rhymebox: {
    position: 'absolute',
    width: `100%`,
    height: '1.5rem',
    backgroundColor: '#333',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.0rem',
    fontFamily: 'monospace',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  aiBox: {
    position: 'absolute',
    width: `100%`,
    height: '1.5rem',
    backgroundColor: '#333',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.0rem',
    fontFamily: 'monospace',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  lineinidi: {
    position: 'absolute',
    width: `2px`,
    height: '19px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: offWhite,
    color: 'white',
    padding: '0px',
    borderRadius: '0px',
  }
};



export { darkTheme, editorStyle, boxStyle };

'use client'

import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Backdrop,
  LinearProgress,
  Snackbar,
  Alert,
} from '@mui/material'
import { CloudUpload } from '@mui/icons-material'
import { Refresh as RefreshIcon } from '@mui/icons-material';
import '@fontsource/oswald'
import TheChatbot from './TheCB'

const notionBlack = '#191919'
const notionGrey = '#202020'
const offWhite = '#CFCFCF'
const magenta = '#FF00FF'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: offWhite,
    },
    secondary: {
      main: notionBlack,
    },
    background: {
      default: notionBlack,
      paper: notionGrey,
    },
    text: {
      primary: offWhite,
      secondary: offWhite,
    },
  },
  typography: {
    fontFamily: 'Oswald, Arial, sans-serif',
    h4: {
      fontWeight: 500,
      color: offWhite,
    },
    h6: {
      fontWeight: 400,
      color: offWhite,
    },
    button: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: offWhite,
          backgroundColor: notionGrey,
          '&:hover': {
            backgroundColor: '#2c2c2c',
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: notionBlack,
            color: offWhite,
            '&:hover': {
              backgroundColor: magenta,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
  },
})

const API_BASE_URL = 'https://think.philspeiser.com/analysetrack';
const PROGRESS_API_URL = 'https://serve.philspeiser.com/api/dbgetfeedbacktask';

const MAX_FILE_SIZE = 80 * 1024 * 1024; // 80MB in bytes

export default function TheFeedback() {
  const [audioFile, setAudioFile] = React.useState(null)
  const [status, setStatus] = React.useState('idle') // 'idle', 'uploading', 'processing', 'complete'
  const [uploadProgress, setUploadProgress] = React.useState(0)
  const [processingProgress, setProcessingProgress] = React.useState(0)
  const [processingMessage, setProcessingMessage] = React.useState('')
  const [toast, setToast] = React.useState(null)
  const [taskId, setTaskId] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const fileInputRef = React.useRef(null)
  const [contextAttachment, setContextAttachment] = React.useState(null)


  const validateFile = (file) => {
    if (!file) {
      setErrorMessage('Please select a file.');
      return false;
    }
  
    const fileName = file.name.toLowerCase();
    const fileExtension = fileName.split('.').pop();
    const isWav = fileExtension === 'wav';
    const isMp3 = fileExtension === 'mp3';
  
    if (!isWav && !isMp3) {
      setErrorMessage('Unsupported file type. Please upload a WAV or MP3 file.');
      return false;  // Fixed: return false instead of undefined
    }
  
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage('File size exceeds 80MB limit. Please choose a smaller file.');
      return false;
    }
  
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function(e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 12); // Read 12 bytes instead of 4
        let header = "";
        for(let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        
        // Check file signatures
        if (isWav && header.startsWith('52494646')) {  // "RIFF"
          resolve(true); // Valid WAV
        } else if (isMp3 && (header.startsWith('4944') || header.startsWith('fffb'))) {  // ID3 or MPEG frame sync
          resolve(true); // Valid MP3
        } else {
          setErrorMessage('The file appears to be corrupted or unreadable.');
          resolve(false);
        }
      };
  
      reader.onerror = function() {
        setErrorMessage('Error reading the file. It may be corrupted.');
        resolve(false);
      };
  
      reader.readAsArrayBuffer(file);
    });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click()
  }

  const handleReload = () => {
    window.location.reload();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setErrorMessage(null); // Clear any previous error message
    
    if (file) {
      const isValid = await validateFile(file);
      if (isValid) {
        setAudioFile(file);
        setToast({ severity: 'success', message: 'File validated successfully.' });
      }
    }
  };

  const handleAnalysis = async () => {
    if (!audioFile) {
      setToast({ severity: 'error', message: 'Please select an audio file first.' })
      return
    }

    setStatus('uploading')
    setUploadProgress(0)
    setTaskId(null)
    setContextAttachment(null)

    const formData = new FormData()
    formData.append('file', audioFile)

    const userString = localStorage.getItem('user');
    let userEmail = '';
    let userName = '';
  
    if (userString) {
      const userData = JSON.parse(userString);
      userEmail = userData.email || '';       // Extract user email from localStorage
      userName = userData.displayName || '';  // Extract user's display name from localStorage
    } else {
      console.error('No user email found in localStorage');
      setToast({ severity: 'error', message: 'User not found. Please log in.' });
      setStatus('idle');
      return;
    }

    formData.append('email', userEmail);
    formData.append('name', userName);

    try {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${API_BASE_URL}/analyze-song`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setUploadProgress(percentCompleted);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200 || xhr.status === 201) {
          const result = JSON.parse(xhr.responseText);
          if (result.task_id) {
            setTaskId(result.task_id);
            setStatus('processing');
            checkProgress(result.task_id);
          } else {
            throw new Error('Invalid response format from the server.');
          }
        } else {
          throw new Error('Failed to start analysis');
        }
      };

      xhr.onerror = () => {
        console.error('Network error occurred during analysis');
        setToast({ severity: 'error', message: 'Network error occurred during analysis.' });
        setStatus('idle');
      };

      xhr.send(formData);
    } catch (error) {
      console.error('Error:', error);
      setToast({ severity: 'error', message: error.message || 'An error occurred during stem splitting.' });
      setStatus('idle');
    }
  };

  const checkProgress = async (id) => {
    try {
      const response = await fetch(`${PROGRESS_API_URL}?task_id=${id}`);
      const data = await response.json()


      console.log('Progress:', data);

      if (data.progress < 100) {
        setProcessingProgress(parseInt(data.progress))

        if (data.message == 'pending')
        {
          setProcessingMessage(
            'Waiting for the next processing slot...'
          );
        }
        else
        {
          setProcessingMessage(data.message);
        }
        
        setTimeout(() => checkProgress(id), 3000)
      } else if (data.progress === 100 && data.result) {
        setStatus('complete');
        const attachment = {
          content: data.result
        }
        setContextAttachment(attachment)
        setToast({ severity: 'success', message: 'Analysis complete!' })
      } else {
        throw new Error('Unexpected response from server')
      }
    } catch (error) {
      console.error('Error checking progress:', error)
      setToast({ severity: 'error', message: 'Error checking progress. Please try again.' })
      setStatus('idle')
    }
  }

  const getProgressMessage = (message) => {
    return message ? message : "Processing...";
  }

  const UploadSection = () => (
    <>
      <Card sx={{ backgroundColor: 'background.paper' }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography 
              variant="body1" 
              sx={{ 
                mr: 2,
                flexShrink: 1,
                minWidth: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {audioFile ? `Loaded: ${audioFile.name}` : 'No audio file selected'}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUpload />}
              onClick={triggerFileInput}
              disabled={status === 'uploading' || status === 'processing'}
              sx={{
                backgroundColor: notionBlack,
                color: magenta,
                '&:hover': {
                  backgroundColor: '#2c2c2c',
                },
                whiteSpace: 'nowrap',
                flexShrink: 0,
              }}
            >
              {audioFile ? 'REPLACE AUDIO' : 'LOAD AUDIO'}
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              accept="audio/wav,audio/mpeg"
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
            {errorMessage && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {errorMessage}
        </Typography>
      )}
          </Box>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          {status === 'uploading' && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" color="text.secondary" align="center">
                Uploading: {uploadProgress}%
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          variant="contained"
          onClick={handleAnalysis}
          disabled={!audioFile || status === 'uploading' || status === 'processing'}
          sx={{
            backgroundColor: notionGrey,
            padding: '18px 30px',
            fontSize: '1.1rem',
            fontWeight: 700,
            color: offWhite,
            '&:hover': {
              backgroundColor: '#2c2c2c',
            },
            '&:disabled': {
              backgroundColor: '#1a1a1a',
              color: '#999999',
            },
          }}
        >
          {status === 'uploading' ? 'Uploading...' : status === 'processing' ? 'Listening...' : 'Start'}
        </Button>
      </Box>
    </>
  )

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ maxWidth: '4xl', margin: 'auto', p: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>THE_FEEDBACK</Typography>
          {status === 'complete' && (
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<RefreshIcon />} 
              onClick={handleReload}
            >
              RE-START
            </Button>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {status !== 'complete' && <UploadSection />}
        </Box>

        <Snackbar open={!!toast} autoHideDuration={6000} onClose={() => setToast(null)}>
          <Alert onClose={() => setToast(null)} severity={toast?.severity || "success"} sx={{ width: '100%' }}>
            {toast?.message}
          </Alert>
        </Snackbar>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={status === 'processing'}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress color="inherit" />
            <Typography variant="h6" sx={{ mt: 2 }}>
              {getProgressMessage(processingMessage)}
            </Typography>
          </Box>
        </Backdrop>

        {status === 'complete' && (
          <Box sx={{ minHeight: '200px', height: 'calc(100vh - 300px)' }}>
            <TheChatbot contextAttachment={contextAttachment} />
          </Box>
        )}
        <Box sx={{ height: '50px' }} />
      </Box>
    </ThemeProvider>
  )
}
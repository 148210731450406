import { useCallback, useEffect, useState } from 'react'

const getViewportSize = () => {
  if (typeof window === 'undefined') return [0, 0];
  
  if (window.visualViewport) {
    return [window.visualViewport.width, window.visualViewport.height];
  }

  return [window.innerWidth, window.innerHeight];
}

const useViewportSize = () => {
  const [viewportSize, setViewportSize] = useState(getViewportSize);

  const updateViewportSize = useCallback(() => {
    setViewportSize(getViewportSize());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      updateViewportSize();
      setTimeout(updateViewportSize, 100);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    window.visualViewport?.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
      window.visualViewport?.removeEventListener('resize', handleResize);
    };
  }, [updateViewportSize]);

  return viewportSize;
}

// Make sure to use a default export
export default useViewportSize;
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.js';
import { Box, Button, CircularProgress, Slider } from '@mui/material';
import { PlayArrow, Pause, Clear, Add } from '@mui/icons-material';

const REGION_COLOR = 'rgba(100, 200, 100, 0.9)';
const MARKER_COLOR = '#FF00FF';

export default function WaveformDisplay({ 
  audioFile, 
  regionStart, 
  setRegionStart, 
  regionEnd, 
  setRegionEnd, 
  transcriptionResult,
  onChordOnsetsChange,
  bpm,
  visible
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [onsetWaveThreshold, setOnsetWaveThreshold] = useState(0.5);

  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const regionsPlugin = useRef(null);
  const regionsRef = useRef(new Map());
  const transcriptionRef = useRef(null);

  const quarterNoteDuration = useMemo(() => 60 / bpm, [bpm]);

  

  //console.log(duration);

  const createQuarterNoteMarkers = useCallback(() => {
    if (!duration || bpm === 0) return { markers: [], count: 0 };
    
    // Calculate the number of bars
    const secondsPerBar = 4 * 60 / bpm; // 4 beats per bar
    const numberOfBars = Math.round(duration / secondsPerBar);
    
    // Calculate the number of quarter notes
    const totalQuarterNotes = numberOfBars * 4;
    
    // Calculate the duration of each quarter note
    const quarterNoteDuration = duration / totalQuarterNotes;
    
    // Create the markers, including the end point
    const markers = Array.from(
      { length: totalQuarterNotes + 1 }, 
      (_, i) => i * quarterNoteDuration
    );
    
    return { markers, count: markers.length };
  }, [duration, bpm]);
  
  const { markers: quarterNoteMarkers, count: totalMarkers } = useMemo(
    () => createQuarterNoteMarkers(),
    [createQuarterNoteMarkers]
  );



  const cleanupCloseOnsets = useCallback((onsets) => {
    if (onsets.length < 2) return onsets;
  
    const cleanedOnsets = [];
    let i = 0;
  
    while (i < onsets.length) {
      if (i === onsets.length - 1) {
        cleanedOnsets.push(onsets[i]);
        break;
      }
  
      const current = onsets[i];
      const next = onsets[i + 1];
  
      if (next - current < 0.1) {
        cleanedOnsets.push((current + next) / 2);
        i += 2;
      } else {
        cleanedOnsets.push(current);
        i++;
      }
    }
  
    return cleanedOnsets;
  }, []);

  const filteredOnsets = useMemo(() => {
    if (!transcriptionRef.current) return [];

    let onsets = transcriptionRef.current.onsets
      .filter(onset => onset.score >= onsetWaveThreshold)
      .map(onset => onset.time);

    if (onsets.length === 0 || onsets[0] > 0.2) {
      onsets = [0, ...onsets];
    }

    return cleanupCloseOnsets(onsets);
  }, [onsetWaveThreshold, cleanupCloseOnsets, transcriptionRef.current]);

  const updateChordOnsets = useCallback(() => {
    if (regionsPlugin.current) {
      const onsets = Array.from(regionsRef.current.values())
        .map(region => region.start)
        .sort((a, b) => a - b);
      onChordOnsetsChange(onsets);
    }
  }, [onChordOnsetsChange]);

  const createRegion = useCallback((start) => {
    if (!regionsPlugin.current || regionsRef.current.has(start)) return;

    const newRegion = regionsPlugin.current.addRegion({
      start,
      color: REGION_COLOR,
      drag: true,
      resize: false,
    });

    const handleUpdate = () => {
      regionsRef.current.set(newRegion.start, newRegion);
      updateChordOnsets();
    };

    
  

    const handleRemove = (e) => {
      e.stopPropagation();
      newRegion.remove();
      regionsRef.current.delete(start);
      updateChordOnsets();
    };

    newRegion.on('update', handleUpdate);
    newRegion.on('dblclick', handleRemove);

    regionsRef.current.set(start, newRegion);
  }, [updateChordOnsets]);

  const updateRegions = useCallback(() => {
    if (!regionsPlugin.current) return;

    const currentOnsets = new Set(filteredOnsets);

    // Remove regions that are no longer in filteredOnsets
    regionsRef.current.forEach((region, start) => {
      if (!currentOnsets.has(start)) {
        region.remove();
        regionsRef.current.delete(start);
      }
    });

    // Add new regions
    filteredOnsets.forEach(onset => {
      if (!regionsRef.current.has(onset)) {
        createRegion(onset);
      }
    });

    updateChordOnsets();
  }, [filteredOnsets, createRegion, updateChordOnsets]);

  const initializeWaveSurfer = useCallback(() => {
    if (!audioFile || !waveformRef.current) return;

    setIsLoading(true);

    if (wavesurfer.current) {
      wavesurfer.current.destroy();
    }

    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: 'violet',
      progressColor: '#FF00FF',
      responsive: true,
      height: 100,
      backend: 'WebAudio',
      cursorColor: '#FF00FF',
    });

    regionsPlugin.current = wavesurfer.current.registerPlugin(RegionsPlugin.create());

    wavesurfer.current.load(URL.createObjectURL(audioFile));

    wavesurfer.current.on('ready', () => {
      setIsLoading(false);
      setDuration(wavesurfer.current.getDuration());
      setRegionStart(0);
      setRegionEnd(wavesurfer.current.getDuration());
    });

    waveformRef.current.addEventListener('dblclick', handleDoubleClick);

    wavesurfer.current.on('play', () => setIsPlaying(true));
    wavesurfer.current.on('pause', () => setIsPlaying(false));

    wavesurfer.current.on('error', (err) => {
      console.error('WaveSurfer error:', err);
      setIsLoading(false);
    });

  }, [audioFile, setRegionStart, setRegionEnd]);

  const handleDoubleClick = (event) => {
    if (!wavesurfer.current) return;

    const waveformRect = waveformRef.current.getBoundingClientRect();
    const clickX = event.clientX - waveformRect.left;
    const waveformWidth = waveformRect.width;
    const clickTime = (clickX / waveformWidth) * wavesurfer.current.getDuration();

    createRegion(clickTime);
    updateChordOnsets();
  };

  const handlePlayPause = useCallback(() => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
    }
  }, []);

  const handleOnsetWaveThreshold = useCallback((event, newValue) => {
    setOnsetWaveThreshold(newValue);
  }, []);

  useEffect(() => {
    if (audioFile) {
      initializeWaveSurfer();
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, [audioFile, initializeWaveSurfer]);

  useEffect(() => {
    if (transcriptionResult) {
      transcriptionRef.current = transcriptionResult;
      updateRegions();
    }
  }, [transcriptionResult, updateRegions]);

  useEffect(() => {
    updateRegions();
  }, [updateRegions]);


  const clearAllRegions = useCallback(() => {
    if (regionsPlugin.current) {
      regionsPlugin.current.clearRegions();
      regionsRef.current.clear();
      updateChordOnsets();
    }
  }, [updateChordOnsets]);

  const addRegionsForBars = useCallback(() => {
    clearAllRegions();
    
    const barMarkers = quarterNoteMarkers.filter((_, index) => index % 4 === 0);
    
    barMarkers.forEach(marker => {
      createRegion(marker);
    });
    
    updateChordOnsets();
  }, [clearAllRegions, quarterNoteMarkers, createRegion, updateChordOnsets]);

  const addRegionsForHalfBars = useCallback(() => {
    clearAllRegions();
    
    const barMarkers = quarterNoteMarkers.filter((_, index) => index % 2 === 0);
    
    barMarkers.forEach(marker => {
      createRegion(marker);
    });
    
    updateChordOnsets();
  }, [clearAllRegions, quarterNoteMarkers, createRegion, updateChordOnsets]);

  return (
    <Box
      sx={{
        width: '100%',
        visibility: visible ? 'visible' : 'hidden',
        position: 'relative',
        height: visible ? 'auto' : 0,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 1 }}>
        <Button
          variant="contained"
          onClick={handlePlayPause}
          startIcon={isPlaying ? <Pause /> : <PlayArrow />}
          sx={{ mr: 2, backgroundColor: '#202020', color: '#CFCFCF' }}
        >
          {isPlaying ? 'Pause' : 'Play'}
        </Button>
        <Button
          variant="contained"
          onClick={clearAllRegions}
          startIcon={<Clear />}
          sx={{ mr: 2, backgroundColor: '#202020', color: '#CFCFCF' }}
        >
          Clear All Regions
        </Button>
        <Button
          variant="contained"
          onClick={addRegionsForBars}
          startIcon={<Add />}
          sx={{ mr: 2, backgroundColor: '#202020', color: '#CFCFCF' }}
        >
          Add Regions for Bars
        </Button>
        <Button
          variant="contained"
          onClick={addRegionsForHalfBars}
          startIcon={<Add />}
          sx={{ mr: 'auto', backgroundColor: '#202020', color: '#CFCFCF' }}
        >
          Add Regions for Half-Bars
        </Button>
        <Box sx={{ width: '150px', mr: 2 }}>Chord-Change Detection Sensitivity</Box>
        <Slider
          value={onsetWaveThreshold}
          onChange={handleOnsetWaveThreshold}
          aria-labelledby="onset-wave-threshold-slider"
          valueLabelDisplay="auto"
          step={0.01}
          marks
          min={0}
          max={1}
          sx={{
            width: '200px', // Set the fixed width for the slider
            color: MARKER_COLOR,
            '& .MuiSlider-thumb': {
              backgroundColor: MARKER_COLOR,
            },
            '& .MuiSlider-track': {
              backgroundColor: MARKER_COLOR,
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#CFCFCF',
            },
          }}
        />
      </Box>
      <Box sx={{ width: '100%', height: visible ? 10 : 0, display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        {quarterNoteMarkers.map((marker, index) => (
          <Box
            key={marker}
            sx={{
              width: '1px',
              height: '100%',
              backgroundColor: index % 4 === 0 ? 'red' : 'gray',
            }}
          />
        ))}
      </Box>
      <Box ref={waveformRef} sx={{ width: '100%', mt:2, height: visible ? 100 : 0 }}></Box>
    
      {isLoading && <CircularProgress sx={{ mt: 2 }} />}
    </Box>
  );
  
  
}
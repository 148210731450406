import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Typography, Box, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const magenta = '#FF00FF';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#CFCFCF',
    },
    '&:hover fieldset': {
      borderColor: magenta,
    },
    '&.Mui-focused fieldset': {
      borderColor: magenta,
    },
  },
  '& .MuiInputLabel-root': {
    color: '#CFCFCF',
    '&.Mui-focused': {
      color: magenta,
    },
  },
  '& .MuiInputBase-input': {
    color: '#CFCFCF',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: magenta,
  color: '#191919',
  '&:hover': {
    backgroundColor: '#D700D7',
  },
}));

const LoadingOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(25, 25, 25, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
});

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post('https://psproducts.vercel.app/api/subauth', {
        email: email,
        password: password
      });

    

      if (response.data.user.subscription == "active") {
        
          localStorage.setItem('user', JSON.stringify({
            email: response.data.user.username,
            nicename: response.data.user.first_name,
            displayName: response.data.user.first_name,
            displayParam: response.data.user.first_name
          }));
          localStorage.setItem('lastSubscriptionCheck', Date.now().toString());
          onLogin(response.data.user.username);
          navigate('/');
        } else if (response.data.message =="Authentication successful.") {
           {
          setError('You do not have an active Membership. Please subscribe to THE_CIRCLE to continue.');
        }
      } else {
        setError('Authentication failed:', response.data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Wrong email or password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      sx={{ 
        mt: 1, 
        maxWidth: 400, 
        margin: 'auto', 
        padding: 3, 
        borderRadius: 2,
        backgroundColor: '#191919',
        position: 'relative',
      }}
    >
      {isLoading && (
        <LoadingOverlay>
          <CircularProgress sx={{ color: magenta }} />
        </LoadingOverlay>
      )}
      <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#CFCFCF' }}>
        Login
      </Typography>
      <StyledTextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <StyledTextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{ color: '#CFCFCF' }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <StyledButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={isLoading}
      >
        {isLoading ? 'Signing In...' : 'Sign In'}
      </StyledButton>
      {error && (
        <Typography color="error" align="center">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default Login;